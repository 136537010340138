import { BaseFormModel, displayName, hasMaxLength, isFloatNumber, isNumberFractionalPartIsLessThanOrEqual, isRequired } from '@Helpers';
import {  makeObservable, observable } from 'mobx';

export class CorectionFormModel extends BaseFormModel<CorectionFormModel> {
    @isRequired()
    @isFloatNumber()
    @isNumberFractionalPartIsLessThanOrEqual(2)
    @displayName('Actual Amount')
    @observable
    public amount: string = '';

    @isRequired()
    @displayName('Comment')
    @hasMaxLength(255)
    @observable
    public correctionComment: string = '';

    constructor() {
        super();
        makeObservable(this);
    }
}
