import { Component } from 'react';
import { computed } from 'mobx';
import { ValueType } from 'react-select';
import './function-filter.scss';

import { PersonFunctions } from '@AppConstants';
import { Select } from '@Components';

type FunctionFilterProps = {
    selectedFunction: string | null;
    setFunctonFilter: (func: string | null) => void;
};

export class FunctionFilter extends Component<FunctionFilterProps, {}> {
    render() {
        return (
            <div className="function-filter">
                <Select
                    options={this._functionOptions}
                    value={this._selectedOption}
                    isClearable
                    onChange={this.onFunctionChangedHandler}
                />
            </div>
        );
    }

    @computed
    private get _selectedOption() {
        const { selectedFunction } = this.props;
        return this._functionOptions.find(o => o.value === selectedFunction);
    }

    private get _functionOptions() {
        return [
            { value: PersonFunctions.COCKPIT, label: `-${PersonFunctions.COCKPIT} group-` },
            { value: PersonFunctions.PIC, label: PersonFunctions.PIC },
            { value: PersonFunctions.FO, label: PersonFunctions.FO },
            { value: PersonFunctions.CABIN, label: `-${PersonFunctions.CABIN} group-` },
            { value: PersonFunctions.SC, label: PersonFunctions.SC },
            { value: PersonFunctions.CC, label: PersonFunctions.CC },
            { value: PersonFunctions.GROUND, label: `-${PersonFunctions.GROUND} group-` },
            { value: PersonFunctions.OPR, label: PersonFunctions.OPR },
            { value: PersonFunctions.OGE, label: PersonFunctions.OGE },
            { value: PersonFunctions.OGGE, label: PersonFunctions.OGGE },
            { value: PersonFunctions.OMCC, label: PersonFunctions.OMCC },
            { value: PersonFunctions.OFC, label: PersonFunctions.OFC }
        ];
    }

    public onFunctionChangedHandler = (selectedOption: ValueType<{ label: string, value: string }, false>) => {
        const value = selectedOption ? selectedOption.value : null;
        this.props.setFunctonFilter(value);
    };
}
