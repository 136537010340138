import { action, makeObservable, observable } from 'mobx';

import { GetDataField } from '@Components';
import { ColumnSort } from '../FilterSection/FilterSectionStore';
import { ColumnFiltersMap, ColumnSortModel, FilterValue } from '../FilterPopup/FilterPopup';

export type TableFilterState = {
    columnSort: ColumnSortModel | null;
    columnFilters: ColumnFiltersMap;
};

export class FilterTableStore {
    private _tabId?: string;
    @observable public columnSort: ColumnSortModel | null = null;
    @observable public columnFilters: ColumnFiltersMap = new Map();

    constructor(tabId?: string) {
        this._tabId = tabId;
        this._getTableFilters();
        makeObservable(this);
    }

    private _getTableFilters() {
        if (!this._tabId) return;

        const config = localStorage.getItem(`tableFilters_${this._tabId}`);
        const tableFilters = config ? JSON.parse(config) as TableFilterState : null;

        if (!tableFilters) return;

        this.columnFilters = new Map(tableFilters.columnFilters);
        this.columnSort = tableFilters.columnSort;
    }

    @action.bound
    public setColumnSort(sortType: ColumnSort, dataField: string, getDataField?: GetDataField) {
        if (this.columnSort?.dataField === dataField && this.columnSort.sortType === sortType) {
            this.columnSort = null;
        } else {
            this.columnSort = { dataField, sortType, getDataField };
        }
        this._saveTableFilterState();
    }

    @action.bound
    public setColumnFilters(filter: FilterValue, dataField: string, getDataField?: GetDataField) {
        const columnFilters = this.columnFilters.get(dataField)?.filterValues;

        if (columnFilters) {
            const index = columnFilters.findIndex(f => f === filter);
            if (index === -1) {
                columnFilters.push(filter);
                this._saveTableFilterState();
                return;
            }

            columnFilters.splice(index, 1);

            if (!columnFilters.length) {
                this.columnFilters.delete(dataField);
            }

            this._saveTableFilterState();
            return;
        }

        this.columnFilters.set(dataField, { filterValues: [filter], getDataField: getDataField });
        this._saveTableFilterState();
    }

    private _saveTableFilterState() {
        if (!this._tabId) return;

        const tableFilters = {
            columnSort: this.columnSort,
            columnFilters: this.columnFilters
        };

        localStorage.setItem(`tableFilters_${this._tabId}`, JSON.stringify(tableFilters));
    }

    @action.bound
    public clearFilters(){
        this.columnSort = null;
        this.columnFilters = new Map();

        if (!this._tabId) return;
        localStorage.removeItem(`tableFilters_${this._tabId}`);
    }

    @action.bound
    public getIsFilterActive(dataField: string) {
        return this.columnSort?.dataField === dataField || !!this.columnFilters.get(dataField);
    }
}