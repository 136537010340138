import React, {useState} from 'react';
import {IconType} from 'react-icons';
import {BiLoaderCircle} from 'react-icons/bi';

type PersonErroreProps = {
    title: string;
    Icon: IconType;
    onLoadError?: () => Promise<string>;
};

function PersonError({title, onLoadError, Icon}: PersonErroreProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [error, setError] = useState<string>('');

    async function loadErrorMessage() {
        const dateStart = new Date();
        setLoading(true);
        const err = await onLoadError?.();
        if (err) setError('\n' + err);

        const dateEnd = new Date();
        const delta = dateEnd.getMilliseconds() - dateStart.getMilliseconds();

        if (delta >= 400) {
            setLoading(false);
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 400 - delta);
        }
    }

    function handleMouseEnter() {
        if (!onLoadError || timer) return;
        const timeout = setTimeout(() => {
            void loadErrorMessage();
        }, 300);
        setTimer(timeout);
    }

    function handleMouseLeave() {
        timer && clearTimeout(timer);
    }

    return (
        <span
            className="period-status error"
            title={title + error}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {loading && <BiLoaderCircle/>}
            {!loading && <Icon/>}
        </span>
    );
}

export {PersonError};