import React from 'react';
import {observable, action, when, IReactionDisposer, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';

import './context-menu.scss';

type ContextMenuProps = {
    container: React.RefObject<HTMLElement>;
    onAppear?: (popupElement: HTMLElement) => boolean;
    onDisappear?: () => void;
    children(popupElement: HTMLElement): JSX.Element | null;
};

@observer
export default class ContextMenu extends React.Component<ContextMenuProps, {}> {
    private _contextMenu: React.RefObject<HTMLDivElement> = React.createRef();
    private _popupMouseEvent: MouseEvent | null = null;
    private _contextMenuWhenDispower: IReactionDisposer | null = null;
    @observable private _isVisible: boolean = false;
    @observable.ref private _popupElement: HTMLElement | null = null;
    @observable private _container: React.RefObject<HTMLElement> | null = null;

    constructor(props: ContextMenuProps) {
        super(props);
        makeObservable(this);

        this._container = props.container;
        this._contextMenuWhenDispower = when(() => !!(this._container && this._container.current), () => {
            const {container} = this.props;
            if (container.current) container.current.addEventListener('contextmenu', this._onTargetContextMenu);
            if (container.current) container.current.addEventListener('scroll', this._onDocumentScroll);
        });
    }

    componentDidMount() {
        document.addEventListener('click', this._onDocumentClick);
        document.addEventListener('scroll', this._onDocumentScroll);
        document.addEventListener('contextmenu', this._onDocumentContextMenu, {capture: true});
        document.addEventListener('keydown', this._onKeydownPress);
    }

    componentWillUnmount() {
        const {container} = this.props;
        if (container.current) container.current.removeEventListener('contextmenu', this._onTargetContextMenu);
        if (container.current) container.current.removeEventListener('scroll', this._onDocumentScroll);
        document.removeEventListener('click', this._onDocumentClick);
        document.removeEventListener('scroll', this._onDocumentScroll);
        document.removeEventListener('contextmenu', this._onDocumentContextMenu, {capture: true});
        document.removeEventListener('keydown', this._onKeydownPress);
        if (this._contextMenuWhenDispower) {
            this._contextMenuWhenDispower();
            this._contextMenuWhenDispower = null;
        }
    }

    @action.bound
    private _onDocumentContextMenu(event: MouseEvent) {
        const prevElement = this._popupElement;
        this._popupElement = event.target as HTMLElement;

        if (prevElement !== this._popupElement) {
            this._onDocumentScroll();
        }
    }

    componentDidUpdate() {
        runInAction(() => {
            this._container = this.props.container;
        });
        this._updateMenuPosition();
    }

    render() {
        if (!this._isVisible) return null;

        return (
            <div ref={this._contextMenu} className="contextMenu" onContextMenu={(e) => this._onTargetContextMenu(e as unknown as MouseEvent)}>
                {this._popupElement && this.props.children(this._popupElement)}
            </div>
        );
    }

    @action.bound
    private _onKeydownPress(event: KeyboardEvent) {
        if (event.code === 'Escape') {
            this._isVisible = false;
        }
    }

    @action.bound
    private _onTargetContextMenu(event: MouseEvent) {
        event.preventDefault();
        this._popupElement = event.target as HTMLElement;

        if (this.props.onAppear) {
            if (!this.props.onAppear(this._popupElement)) {
                return;
            }
        }

        this._isVisible = true;
        this._popupMouseEvent = event;

        setTimeout(this._updateMenuPosition, 0);
    }

    @action.bound
    private _onDocumentClick(event: MouseEvent) {
        this.props.onDisappear && this.props.onDisappear();

        const target = event.target as HTMLDivElement;
        if (!target || target.classList.contains('menu-action') || target.classList.contains('with-children')) return;

        if (this._isVisible || target !== this._contextMenu.current) this._isVisible = false;
    }

    @action.bound
    private _onDocumentScroll() {
        if (this._isVisible) this._isVisible = false;
    }

    @action.bound
    private _updateMenuPosition() {
        if (!this._popupMouseEvent) return;
        const clickX = this._popupMouseEvent.clientX;
        const clickY = this._popupMouseEvent.clientY;
        this._popupMouseEvent = null;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const contextMenu = this._contextMenu.current;
        if (contextMenu) {
            const rootW = contextMenu.offsetWidth;
            const rootH = contextMenu.offsetHeight;

            const right = (screenW - clickX) > rootW;
            const left = !right;
            const top = (screenH - clickY) > rootH;
            const bottom = !top;

            if (right) {
                contextMenu.style.left = `${clickX + 5}px`;
            }

            if (left) {
                contextMenu.style.left = `${clickX - rootW - 5}px`;
            }

            if (top) {
                contextMenu.style.top = `${clickY + 5}px`;
            }

            if (bottom) {
                contextMenu.style.top = `${clickY - rootH - 5}px`;
            }
        }
    }
}
