import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { appStore } from '@GlobalStores';
import { PersonActivitiesInfo } from '@Models';
import { Activity } from './Components/ActivityPickerStore';
import { ActivityPicker } from './Components/ActivityPicker';
import './duty-filter.scss';

type DutyFilterProps = {
    selectedValues: string[];
    setValue: (values: string[]) => void;
    personActivitiesInfo: PersonActivitiesInfo[];
};

@observer
export class DutyFilter extends Component<DutyFilterProps, {}> {
    constructor(props: DutyFilterProps) {
        super(props);
        this._handleSelectChange = this._handleSelectChange.bind(this);
    }

    render() {
        return (
            <div className="duty-filter">
                <ActivityPicker
                    periodId={appStore.timePeriod?.id}
                    selectedValues={this.props.selectedValues}
                    onChange={this._handleSelectChange}
                    personActivitiesInfos={this.props.personActivitiesInfo}
                />
            </div>
        );
    }

    private _handleSelectChange(selectedOptions: Activity[]) {
        const values = selectedOptions.map(option => option.value);
        this.props.setValue(values);
    }
}