import * as React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@Components';
import { ResultStatus, ValidatePeriodRuleResultModel } from '@Models';
import { Label } from 'reactstrap';
import { action, makeObservable, observable } from 'mobx';

type CheckRuleResultDialogProps = {
    ruleId: number;
    hasNoDuties: boolean;
    validateResult: ValidatePeriodRuleResultModel;
};

@observer
export default class CheckRuleResultDialog extends React.Component<CheckRuleResultDialogProps> implements IModalDialogContent<void> {
    hideNotRelevant = true;

    constructor(props: CheckRuleResultDialogProps) {
        super(props);
        makeObservable(this, {
            hideNotRelevant: observable,
            _handleHideClick: action.bound
        });
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: `Reasons for rule ${this.props.ruleId}`,
            buttons: [{
                title: 'Close',
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }
        ],
            width: '500px',
            bodyClassName: 'create-rule-dialog'
        };
    }

    render() {
        if (this.props.hasNoDuties) {
            return <span>Duties are not imported</span>;
        }
        
        let results = this.props.validateResult.conditionResults || [];
        if (this.hideNotRelevant && this.props.validateResult.conditionResults) {
            results = this.props.validateResult.conditionResults.filter(cr => cr.status !== ResultStatus.NotRelevant);
        }

        return (
            <div>
                <Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                    <Checkbox checked={this.hideNotRelevant} onChange={this._handleHideClick}/> 
                    Hide not relevant conditions
                </Label>
                {results.map((cr, i) => {
                    return (
                        <div key={i} style={{ marginBottom: 20}}>
                            <div><b>Condition:</b> {cr.conditionName}</div>
                            <div><b >Status:</b> <span style={{ color: cr.status === 'Fail' ? 'red' : 'green', fontWeight: cr.status === 'Fail' ? 800 : 'normal' }}>{cr.status}</span></div>
                            <div><b>Details:</b> {cr.details}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    _handleHideClick() {
        this.hideNotRelevant = !this.hideNotRelevant;
    }
}
