import * as React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { FaExclamationTriangle, FaRegComment } from 'react-icons/fa';
import './check-rules-table-dialog.scss';

import { CellData, Column, Grid, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow, TableRowRenderArgs } from '@Components';
import { ApiClient, PermissionType, RuleModel, SpecialRuleType, ValidatePeriodResultResponse, ValidatePeriodRuleResultModel } from '@Models';
import { RulesStore } from '@GlobalStores';
import { DateTimeService, downloadService, securityService } from '@Services';
import { ApiUrls, SpecialRuleTypeLabels } from '@AppConstants';
import CheckRuleResultDialog from '../../../../../Components/CheckRuleResultDialog/CheckRuleResultDialog';
import { modalService } from '../../../../../Components/Modal/ModalService';

type CheckRulesTableDialogProps = {
    personId: string;
    date: Date;
    validationResult: ValidatePeriodResultResponse;
};

@observer
export default class CheckRulesTableDialog extends React.Component<CheckRulesTableDialogProps> implements IModalDialogContent<void> {
    private _store = new RulesStore();
    private _apiClient = new ApiClient();

    constructor(props: CheckRulesTableDialogProps) {
        super(props);
        makeObservable(this, {
        });
    }

    async componentDidMount() {
        const { date } = this.props;
        await this._store.loadRules(date, date);
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: `Rules applicability for ${DateTimeService.toUiDate(this.props.date)}`,
            buttons: [{
                title: 'Download',
                type: ModalButtonType.Download,
                isDisabled: !securityService.hasPermission(PermissionType.ViewRule),
                onClick: async () => {
                    await this.downloadHandler();
                }
            },
            {
                title: 'Close',
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }],
            width: '900px',
            bodyClassName: 'create-rule-dialog'
        };
    }

    render() {
        const { rules, loading } = this._store;

        return (
            <Grid<RuleModel>
                dataSource={rules}
                className="compensation-table"
                groupingKey="ruleGroupId"
                onGroupingRowRender={this._renderGroupingRow}
                loading={loading}
            >
                <Column<RuleModel> caption="Actions" maxWidth={60} cellRender={this._actionCellRenderer} />
                <Column<RuleModel> dataField="id" caption="ID" maxWidth={70} cellRender={this._idCellRender} />
                <Column<RuleModel> dataField="amount" caption="Amount" maxWidth={100} />
                <Column<RuleModel> dataField="unit" caption="Unit" maxWidth={120} cellRender={this._unitCellRender} />
                <Column<RuleModel> dataField="contracts" caption="Contract" cellRender={this._contractsCellRender} maxWidth={170} />
                <Column<RuleModel> dataField="flyingDutyPercentageFrom" caption="Flying %" maxWidth={70} cellRender={this._flyingPercentCellRender} />
                <Column<RuleModel> dataField="specialRuleType" caption="Special Rule Type" cellRender={this._specialRuleTypeCellRender} maxWidth={200} />
                <Column<RuleModel> dataField="specialRuleArguments" caption="Special Rule Arguments" cellRender={this._specialRuleArgsCellRender} maxWidth={80} />
                <Column<RuleModel> dataField="successAction" caption="Success Rule Action" cellRender={this._successActionCellRender} maxWidth={80} />
                <Column<RuleModel> dataField="duties" caption="Duty" cellRender={this._dutiesCellRender} />
                <Column<RuleModel> dataField="functions" caption="Function" cellRender={this._functionsCellRender} />
            </Grid>
        );
    }

    public downloadHandler = async () => {
        const params = {
            personId: this.props.personId,
            date: this.props.date
        };
        await downloadService.downloadFile(ApiUrls.RulesValidateDownloadUrl, params);
    };

    private _renderGroupingRow = (args: TableRowRenderArgs<RuleModel>) => {
        const { item } = args;

        args.child = <>
            {item.groupName}{item.bandName ? ` (${item.bandName})` : ''} - {item.sapAccount}
        </>;
    };

    private _actionCellRenderer = (cellData: CellData<RuleModel>) => {
        const { row } = cellData;
        const hasNoDuties = this.props?.validationResult?.hasNoDuties;
        const ruleValidation = this.props?.validationResult?.ruleResults?.find(x => x.ruleId === row?.id);

        return (
            <>
                {
                    this.props.validationResult && (ruleValidation || hasNoDuties) &&
                    <FaExclamationTriangle
                        size={14}
                        style={{ cursor: 'pointer', color: '#2684FF', marginLeft: 3 }}
                        title='Show reasons'
                        onClick={() => this._handleShowReasonsClick(row!, ruleValidation!, !!hasNoDuties)}
                    />
                }
            </>
        );
    };

    private _handleShowReasonsClick = async (rule: RuleModel, validateResult: ValidatePeriodRuleResultModel, hasNoDuties: boolean) => {
        await modalService.show(CheckRuleResultDialog, { ruleId: rule.id, validateResult, hasNoDuties });
    };

    private _idCellRender = (cellData: CellData<RuleModel>) => {
        const { row } = cellData;

        let className = '';
        if (this.props.validationResult) {
            const ruleValidation = this.props?.validationResult?.ruleResults?.find(x => x.ruleId === row?.id);
            className = ruleValidation?.isSuccess ? 'validation-success' : 'validation-error';
        }

        return (
            <span className={className}>
                {row?.id}
            </span>
        );
    };

    private _unitCellRender = (cellData: CellData<RuleModel>) => {
        const { unit } = cellData.row!;
        return <>{!!unit && unit}</>;
    };

    private _contractsCellRender = (cellData: CellData<RuleModel>) => {
        const { contracts } = cellData.row!;
        if (!contracts) return null;
        return <>{contracts.join(' ')}</>;
    };

    private _flyingPercentCellRender = (cellData: CellData<RuleModel>) => {
        const { flyingDutyPercentageFrom, flyingDutyPercentageTo } = cellData.row!;
        if (typeof flyingDutyPercentageFrom !== 'number' && typeof flyingDutyPercentageTo !== 'number') {
            return null;
        }

        if (flyingDutyPercentageFrom === -1 && flyingDutyPercentageTo === -1){
            return <>N/A</>;
        }
        if (flyingDutyPercentageFrom === -2 && flyingDutyPercentageTo === -2){
            return <>!0</>;
        }

        return <>{`${typeof flyingDutyPercentageFrom === 'number' ? flyingDutyPercentageFrom.toFixed(2) : '~'} - ${typeof flyingDutyPercentageTo === 'number' ? flyingDutyPercentageTo.toFixed(2) : '~'}`}</>;
    };

    private _specialRuleTypeCellRender = (cellData: CellData<RuleModel>) => {
        const { specialRuleType } = cellData.row!;
        if (!specialRuleType || specialRuleType === SpecialRuleType.None) return null;

        return <>{SpecialRuleTypeLabels[specialRuleType]}</>;
    };

    private _specialRuleArgsCellRender = (cellData: CellData<RuleModel>) => {
        const { specialRuleArguments } = cellData.row!;
        return <>{!!specialRuleArguments && specialRuleArguments}</>;
    };

    private _successActionCellRender = (cellData: CellData<RuleModel>) => {
        const { successAction } = cellData.row!;
        return <>{successAction}</>;
    };

    private _dutiesCellRender = (cellData: CellData<RuleModel>) => {
        const { duties, dutyComment } = cellData.row!;
        if (!duties) return null;

        return (
            <>
                <span style={{ marginRight: 5 }}>
                    {duties.map(d => {
                        if (!d.code) return 'Unknown';
                        const additionalValueLabel = d.additionalValue ? ` = ${d.additionalValue}` : '';
                        return `${d.code} ${d.additionalType ? ` / ${d.additionalType}${additionalValueLabel}` : ''}`;
                    }).join(' ')}
                </span>
                {!!dutyComment &&
                    <>
                        <FaRegComment style={{ alignSelf: 'center', marginRight: 5 }} />
                        {dutyComment}
                    </>}
            </>
        );
    };

    private _functionsCellRender = (cellData: CellData<RuleModel>) => {
        const { functions } = cellData.row!;
        if (!functions) return null;
        return <>{functions.join(' ')}</>;
    };
}
