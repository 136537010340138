import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import ContextMenu from '@Components/ContextMenu/ContextMenu';
import { ContextMenuItem } from '@Components/ContextMenu/ContextMenuItem';
import { ApiClient, IValidateParams, PermissionType } from '@Models';
import { DateTimeService, securityService } from '@Services';
import { modalService } from '../../../../../Components/Modal/ModalService';
import CheckRulesTableDialog from '../Dialogs/CheckRulesTableDialog';

type DutyContextMenuProps = {
    container: React.RefObject<HTMLElement>;
    personId: string;
};

@observer
export default class DutyContextMenu extends React.Component<DutyContextMenuProps, {}>{
    private _apiClient = new ApiClient();

    render() {
        return (
            <ContextMenu container={this.props.container}>
                {(element) => this._renderMenu(element)}
            </ContextMenu>
        );
    }

    private _renderMenu(target: HTMLElement): React.ReactElement<HTMLElement> | null {
        const dateTarget = target.closest('[data-menu-date]') as HTMLElement | null;
        const dateStr = dateTarget && dateTarget.getAttribute('data-menu-date');
        if (!dateStr) return null;

        const date = DateTimeService.fromString(dateStr);

        return (
                <ContextMenuItem title="Check Rules" onClick={() => this._checkRules(date)} disabled={!securityService.hasPermission(PermissionType.ViewRule)} />
        );
    }

    @action.bound
    private async _checkRules(date: Date | null) {
        if (!date) return;

        const params: IValidateParams = {
            date: date,
            personId: this.props.personId
        };

        const { data } = await this._apiClient.ruleValidateGet(params);

        await modalService.show(CheckRulesTableDialog, { personId: this.props.personId, date: date, validationResult: data });
    }
}