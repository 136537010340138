import React, { Component } from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FaEdit } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';

import { PersonDaySalaryItem } from '@Models';
import { CompletionType, PromiseCompletion } from '@Helpers';
import { DateTimeService } from '@Services';
import { Input, Loading, ToolTipItem } from '@Components';
import './correction-cell.scss';

export type CellChangingArgs = {
	value: string;
	cancel: boolean;
};

type CorrectionCellProps = {
	initialValue?: number;
	calculatedValue?: number;
	correction?: PersonDaySalaryItem;
	onChanging: (args: CellChangingArgs, onUpdate: (updatePromise: Promise<unknown>) => void) => Promise<void>;
	disabled?: boolean;
};

@observer
export class CorrectionCell extends Component<CorrectionCellProps> {
	private _nodeId: string = 'cell-' + uuid();
	private _loader: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);
	@observable private _value = '';
	@observable private _oldValue = '';
	@observable.ref private _inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: CorrectionCellProps) {
		super(props);
		const value = typeof props.initialValue === 'number' ? Number(props.initialValue).toFixed(2) : '';
		this._oldValue = value;
		this._value = value;
		makeObservable(this);
	}

	componentDidUpdate(oldProps: CorrectionCellProps) {
		const { initialValue } = this.props;
		if (oldProps.initialValue !== initialValue) {
			const value = typeof initialValue === 'number' ? Number(initialValue).toFixed(2) : '';
			runInAction(() => {
				this._value = value;
				this._oldValue =value;
			});
		}
	}

	render() {
		const { correction, disabled } = this.props;

		return (
			<>
				{this._loader.isPending && <Loading loading customSize={12} />}
				{correction && <>
					<FaEdit className={'correction-icon ' + (disabled ? 'icon-disabled' : '')} id={this._nodeId} onClick={this._openEditor} />
					<ToolTipItem text={this._getToolTipsText()} placement="top" targetId={this._nodeId} />
				</>}
				<Input
					innerRef={this._inputRef}
					className="input-cell"
					value={this._value}
					onFocus={() => this._inputRef.current?.select()}
					onChange={this.handleValueChange}
					onBlur={this._trySaveValue}
					onKeyDown={this._onInputKeyDown}
					disabled={disabled}
					onInput={() => {
						const input = this._inputRef.current;
						if (input) {

							let value = input.value;
							const isNegative = value.startsWith('-');
							
							value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
							if (isNegative){
								value = '-' + value;
							}

							input.value = value;
						}
					}}
				/>
			</>
		);
	}

	@action.bound
	private handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
		this._value = e.target.value;
	}

	private _trySaveValue = async () => {
		if (this._value !== this._oldValue) {
			await this._openEditor();
		}
	};

	private _openEditor = async () => {
		if (this.props.disabled)
			return;

		const args: CellChangingArgs = {
			value: this._value,
			cancel: false
		};
		await this._loader.subscribe(this.props.onChanging(args, this._onUpdate));
		runInAction(() => {
			if (args.cancel) {
				this._value = this._oldValue || '';
			} else {
				this._oldValue = this._value;
			}
		});
	};

	private _onUpdate = (updatePromise: Promise<unknown>) => {
		void this._loader.subscribe(updatePromise);
	};

	private _onInputKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			this._inputRef.current?.blur();
		} else if (e.key === 'Escape') {
			runInAction(() => {
				this._value = this._oldValue || '';
				this._inputRef.current?.blur();
			});
		}
	};

	private _getToolTipsText(): JSX.Element {
		const { correction, calculatedValue } = this.props;
		return (
			<>
				Calcuated value: {calculatedValue?.toFixed(2)}, corrected to {Number(this._oldValue).toFixed(2)}<br />
				{!!correction && <>
					<i>{correction.comment}</i>
					<br />
					<small>{DateTimeService.toUiDateTime(correction.modifiedDateTimeAt)} by {correction.modifiedPrincipalDisplayName || correction.modifiedPrincipalEmail}</small>
				</>}
			</>
		);
	}
};