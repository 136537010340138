import { InputWithError } from '@Components';
import React, { useEffect, useRef, useState } from 'react';
import './input-cell.scss';

type InputCellProps = {
	initialValue?: string;
	disabled?: boolean;
	onChange: (value: string) => void;
	validate?: (value: string) => string | undefined;
};

export const InputCell: React.FC<InputCellProps> = ({ initialValue, onChange, disabled, validate }) => {

	const [value, setValue] = useState<string>('');
	const inputEl = useRef<HTMLInputElement>(null);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);

	useEffect(() => {
		setValue(initialValue || '');
	}, [initialValue]);

	const blurHandler = () => {
		if (value !== initialValue && !validationErrors.length) {
			onChange(value);
		}
	};

	const keyDownHandler = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			inputEl.current?.blur();
		} else if (e.key === 'Escape') {
			setValue(initialValue || '');
			inputEl.current?.blur();
		}
	};

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!validate) {
			return setValue(e.target.value);
		}

        const err = validate(e.target.value);
        const errors = err ? [err] : [];

		setValue(e.target.value);
		setValidationErrors(errors);
	};

	return (
		<InputWithError
			innerRef={inputEl}
			className="input-cell"
			value={value}
			onChange={changeHandler}
			onBlur={blurHandler}
			onKeyDown={keyDownHandler}
			validationErrors={validationErrors}
			disabled={disabled}
		/>
	);
};