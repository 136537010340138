import React, { Component } from 'react';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FaRegComment } from 'react-icons/fa';

import { Activity } from '@AppConstants';
import { PermissionType, RuleGroupItem, SalaryDailyViewModelResponseItem, SalaryItemType, SalaryPeriodResponseItem, SalaryPeriodStatus } from '@Models';

import { DateTimeService, securityService, stringSearchService } from '@Services';
import { modalService } from '@Components/Modal/ModalService';

import { DutyTableStore } from './DutyTableStore';

import { CellData, CellOptions, Column, DataFieldType, Grid, ModalButtonType, NotificationHandler, TableRowRenderArgs } from '@Components';
import { CellChangingArgs, CorrectionCell } from './Components/CorrectionCell';
import { CorrectionResult, UpdateCorrectionDialog, UpdateCorrectionDialogProps } from './Dialogs/UpdateCorrectionDialog';
import DutyContextMenu from './Components/DutyContextMenu';
import { FilterSectionStore } from '../FilterSection/FilterSectionStore';
import './duty-table.scss';

type DutyTableProps = {
    columns?: string[];
    classPrefix?: string;
    period: SalaryPeriodResponseItem;
    personId: string;
    filterStore: FilterSectionStore;
    onLoading: (personId: string, operation: PromiseLike<unknown>) => void;
    onPersonUpdated: (personId: string) => void;
    onResizeHeader?: (width?: number, height?: number) => void;
};

@observer
class DutyTable extends Component<DutyTableProps> {
    @observable private _dutyListRef: React.RefObject<HTMLDivElement> = React.createRef();
    @observable private _popupEvent: React.MouseEvent | null = null;
    private _store: DutyTableStore = new DutyTableStore();

    constructor(props: DutyTableProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        void this.refresh();
    }

    componentDidUpdate(prevProps: DutyTableProps) {
        if (prevProps.period.id !== this.props.period.id || prevProps.personId !== this.props.personId) {
            void this.refresh();
        }

        runInAction(() => {
            if (this._popupEvent) {
                this._popupEvent = null;
            }
        });
    }

    render() {
        const { onResizeHeader } = this.props;
        const { dataSource, groups } = this._store;

        return (
            <>
                <div ref={this._dutyListRef}>
                    <Grid<SalaryDailyViewModelResponseItem>
                        dataSource={dataSource}
                        gridName="Duty"
                        totalRow
                        onResizeHeader={onResizeHeader}
                        onRowRender={this._rowRender}
                        loading={this._store.loading}
                        allowColumnResize
                        allowHeaderFilters
                    >
                        <Column<SalaryDailyViewModelResponseItem, Date> dataField="date" caption="Date" cellRender={this._dateCell} minWidth={70} onFooterCellPrepare={this._emptyFooter} />
                        <Column<SalaryDailyViewModelResponseItem, string> dataField="duty" caption="Duty" minWidth={150} cellRender={this._dutyCell} onFooterCellPrepare={this._emptyFooter} />
                        <Column<SalaryDailyViewModelResponseItem> dataField="routing" caption="Routing" minWidth={15} onFooterCellPrepare={this._emptyFooter} />
                        <Column<SalaryDailyViewModelResponseItem, number | undefined> dataField="blockHours1DaySec" dataFieldType={DataFieldType.Time} caption="BH" minWidth={15} cellRender={this._timeSpanCell} onFooterCellPrepare={this._emptyFooter} />
                        {groups.map(rg => {
                            return <Column<SalaryDailyViewModelResponseItem>
                                key={rg.id}
                                caption={rg.name}
                                getDataField={(item: SalaryDailyViewModelResponseItem) => item.items.find(x => x.ruleGroupId === rg.id)?.amount}
                                headerCellRender={this._renderRuleGroupHeaderWrapper(rg)}
                                cellRender={this._renderCellWrapper(rg)}
                                footerCellRender={this._renderFooterWrapper(rg)}
                                className="correction-cell"
                            />;
                        })}
                    </Grid>
                </div>
                <DutyContextMenu container={this._dutyListRef} personId={this.props.personId} />
            </>
        );
    }

    private _rowRender = (args: TableRowRenderArgs<SalaryDailyViewModelResponseItem>) => {
        args.rowAttributes['data-menu-date'] = args.item.date;
    };

    private _dateCell = (cellData: CellData<SalaryDailyViewModelResponseItem, Date>) => {
        const { value } = cellData;
        return <>{DateTimeService.toUiDate(value)}</>;
    };

    private _dutyCell = (cellData: CellData<SalaryDailyViewModelResponseItem, string>, cellOptions: CellOptions) => {
        const { dataField, row } = cellData;
        const { duties, remarks, dutyHours1DaySec, actualBlockHours1DaySec } = row!;

        let cellText = dataField ? row![dataField] as string : '';
        let releasedDutyText = row!.releasedDuty;
        let dutyText = row!.duty;

        if (releasedDutyText !== dutyText){
            cellOptions.className += ' released-different';
        }

        const flags: JSX.Element[] = [];
        let hasLongHaul = false;
        let hasIntroduction = false;
        if (dataField) {
            const checkerTrainees: Array<string> = [];
            for (let i = 0; i < duties.length; i++) {
                const duty = duties[i];
                let checkerTrainee = duty.checkerTraineeInfo;
                if (checkerTrainee) {
                    let ctText = `${checkerTrainee.isChecker ? 'Checker' : 'Trainee'} ${checkerTrainee.functionName?.toLocaleUpperCase()}`;
                    ctText += checkerTrainee.instructorQualification ? `-${checkerTrainee.instructorQualification}` : '';
                    ctText += checkerTrainee.relatedPersonCode ? ` with ${checkerTrainee.relatedPersonCode}` : '';
                    if (checkerTrainees.indexOf(ctText) === -1)
                        checkerTrainees.push(ctText);
                }

                if (duty.activityCodeName === Activity.SUPPORT) {
                    flags.push(<span className="flag flag-support">S</span>);
                }

                if (duty.activityCodeName === Activity.PIKETT) {
                    flags.push(<span className="flag flag-pikett">P</span>);
                }

                if (duty.isLongHaul) {
                    hasLongHaul = true;
                }
                if (duty.isIntroduction) {
                    hasIntroduction = true;
                }
            }

            if (checkerTrainees.length > 0)
                cellText += ' / ' + checkerTrainees.join(' / ');

            const remarks = duties.filter(x => !!x.remarks).map(x => x.remarks).join(' / ');
            if (remarks) {
                cellText += ' / ' + remarks;
            }

            if (dutyHours1DaySec) {
                cellText += ' / DH: ' + DateTimeService.toUiSeconds(dutyHours1DaySec);
            }

            if (actualBlockHours1DaySec) {
                cellText += ' / BH: ' + DateTimeService.toUiSeconds(actualBlockHours1DaySec);
            }

            if (hasIntroduction) {
                cellText += ' / 🧪';
            }
        }

        let hint = cellText;
        if (hasLongHaul) {
            hint += ' ✈';
        }

        if (releasedDutyText !== dutyText){
            hint += '\nReleased:\n' + releasedDutyText;
        }

        cellOptions.hint = hint;

        return (
            <>
                <span>
                    {this._renderText(cellText, true)}
                    {!!remarks &&
                        <>
                            <FaRegComment style={{ alignSelf: 'center', marginRight: 5 }} />
                            {remarks}
                        </>}
                </span>
                {flags}
            </>
        );
    };

    private _emptyFooter(): React.CSSProperties {
        return { backgroundColor: '#eee' };
    }

    private _timeSpanCell(cellData: CellData<SalaryDailyViewModelResponseItem, number | undefined>) {
        const { value } = cellData;
        return <>{value ? DateTimeService.toUiSeconds(value) : ''}</>;
    };

    private _renderCellWrapper = (rg: RuleGroupItem) => {
        const { onPersonUpdated } = this.props;
        return (args: CellData<SalaryDailyViewModelResponseItem>) => {
            const { period, personId } = this.props;
            const { row } = args;
            const { date, items } = row!;
            let itemsSum: number | undefined = void 0;

            for (const item of items) {
                if (item.ruleGroupId === rg.id && item.sapAccount === rg.sapAccount && item.unit === rg.unit && item.amount) {
                    if (typeof itemsSum === 'undefined') {
                        itemsSum = item.amount;
                    } else {
                        itemsSum += item.amount;
                    }
                }
            }
            const correction = items.find(x => x.ruleGroupId === rg.id && x.sapAccount === rg.sapAccount && x.unit === rg.unit && x.type === SalaryItemType.ManualCorrection);

            const itemsWithoutCorrection = typeof correction?.amount === 'number' ? (itemsSum ?? 0) - correction.amount : itemsSum;

            const onChangeCorrection = async (args: CellChangingArgs, onUpdate: (updatePromise: Promise<unknown>) => void): Promise<void> => {

                const { button, result } = await modalService.show<UpdateCorrectionDialogProps, CorrectionResult>(UpdateCorrectionDialog, {
                    RuleGroup: rg,
                    correction: correction,
                    value: +args.value || 0,
                    calculatedAmount: itemsWithoutCorrection
                });

                if (button === ModalButtonType.Save && result && typeof result.amount !== 'undefined' && result.amount !== null && result.comment) {
                    const correctionAmount = result.amount - (itemsWithoutCorrection ?? 0);

                    await this._store.updateCorrrection(period.id, personId, date, rg.id, rg.sapAccount, rg.unit, correctionAmount, result.comment, onUpdate);

                    NotificationHandler.showSuccess(`Successfully updated ${rg.name} (${rg.sapAccount})`);
                    onPersonUpdated(personId);
                    return;
                }

                if (button === ModalButtonType.Delete) {
                    await this._store.deleteCorrrection(period.id, personId, date, rg.id, rg.sapAccount, rg.unit);

                    NotificationHandler.showSuccess(`Successfully removed corection to ${rg.name} (${rg.sapAccount})`);
                    onPersonUpdated(personId);
                    return;
                }

                // eslint-disable-next-line require-atomic-updates
                args.cancel = true;
            };

            const isDisabled = !securityService.hasPermission(PermissionType.SalaryManualCorrection) || period.status === SalaryPeriodStatus.Released || (period.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod));
            // TODO: check
            // args.cellAttributes.className += ' alight-right correction-cell';
            return (
                <CorrectionCell
                    correction={correction}
                    calculatedValue={itemsWithoutCorrection}
                    initialValue={itemsSum}
                    onChanging={onChangeCorrection}
                    disabled={isDisabled}
                />
            );
        };
    };

    private _renderFooterWrapper(rg: RuleGroupItem) {
        return (cellData: CellData<SalaryDailyViewModelResponseItem>) => {
            const { dataSource } = cellData;

            if (!dataSource) return null;

            let val = 0;

            for (let i = 0; i < dataSource.length; i++) {
                const row = dataSource[i];

                for (const item of row.items) {
                    if (item.ruleGroupId === rg.id && item.sapAccount === rg.sapAccount && item.unit === rg.unit && item.amount) {
                        val += item.amount;
                    }
                }

            }

            return <>{val ? val.toFixed(2) : '-'}</>;
        };
    }

    private _renderRuleGroupHeaderWrapper = (rg: RuleGroupItem) => () => {
        return (
            <>
                {rg.importType ?? rg.name}
                <br />
                <small>{rg.sapAccount}, {rg.unit === 'Money' ? 'CHF' : rg.unit}</small>
            </>
        );
    };

    private _renderText(text: string | undefined, shouldSearch: boolean) {
        if (!shouldSearch) {
            return text;
        }

        const { searchDutyTerms, textComparer } = this.props.filterStore;
        const parts = stringSearchService.markMatches(text, searchDutyTerms, textComparer);

        return (
            <>
                {parts.map((p, index) => p.isMatch ? <span className="text-match" style={{ backgroundColor: '#FFFF00' }} key={'t' + index}>{p.text}</span> : p.text)}
            </>
        );
    }

    public async refresh() {
        const operation = this._store.loadData(this.props.period.id, this.props.personId);
        this.props.onLoading(this.props.personId, operation);
        await operation;
    }
}

export { DutyTable };