import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { FaRegBookmark } from 'react-icons/fa';

import { ISalaryMonthViewModelResponse, RuleGroupItem } from '@Models';
import { Column, SizeConfig, Grid, ToolTipItem, CellInfo, CellData } from '@Components';
import { ColumnFiltersMap, ColumnSortModel } from '../FilterPopup/FilterPopup';
import './salary-table.scss';


type SalaryTableProps = {
    dataSource: ISalaryMonthViewModelResponse[];
    ruleGroups: RuleGroupItem[];
    classPreffix?: string;
    scrollTop: number;
    highlightedRow?: ISalaryMonthViewModelResponse;
    selectedRow?: ISalaryMonthViewModelResponse;
    totalRow?: boolean;
    scrollHandler: (top: number) => void;
    onCellDoubleClick: (cellInfo: CellInfo<ISalaryMonthViewModelResponse>, rg: RuleGroupItem) => void;
    onRowClick: (row: ISalaryMonthViewModelResponse) => void;
    onRowHover?: (row?: ISalaryMonthViewModelResponse) => void;
    onResizeHeader?: (width?: number, height?: number) => void;
    sizeConfig?: SizeConfig;
    loading?: boolean;
    onChangeTableSort?: (sort: ColumnSortModel | null) => void;
    onChangeTableFilters?: (filters: ColumnFiltersMap) => void;
};

@observer
class SalaryTable extends PureComponent<SalaryTableProps, {}> {

    render() {
        const { selectedRow, highlightedRow, dataSource, totalRow, sizeConfig, ruleGroups, loading, onChangeTableFilters, onChangeTableSort } = this.props;
        const { scrollHandler, onRowHover, onRowClick, onResizeHeader } = this.props;

        return (
            <Grid<ISalaryMonthViewModelResponse>
                dataSource={dataSource}
                gridName="Salary"
                virtualized
                virtualRowHeight={25}
                useScrollbarIndent
                scrollTop={this.props.scrollTop}
                onScroll={scrollHandler}
                selectedRow={selectedRow}
                highlightedRow={highlightedRow}
                onRowClick={onRowClick}
                onRowHover={onRowHover}
                onResizeHeader={onResizeHeader}
                totalRow={totalRow}
                sizeConfig={sizeConfig}
                loading={loading}
                allowColumnResize
                allowHeaderFilters
                onChangeSort={onChangeTableSort}
                onChangeFilters={onChangeTableFilters}
            >
                {ruleGroups.map(rg =>
                    <Column<ISalaryMonthViewModelResponse>
                        key={rg.id}
                        caption={rg.name}
                        onDoubleClick={this._cellDoubleClickHandler(rg)}
                        headerCellRender={this._renderHeaderWrapper(rg)}
                        cellRender={this._renderCellWrapper(rg)}
                        footerCellRender={this._renderFooterWrapper(rg)}
                        className="rg-cell"
                        getDataField={(row) => row.groups?.find(g => g.ruleGroupId === rg.id)?.amount}
                    />
                )}
            </Grid>
        );
    }

    private _renderCellWrapper(rg: RuleGroupItem) {
        return (cellData: CellData<ISalaryMonthViewModelResponse>) => {
            const { groups, personCode } = cellData.row!;
            const currentGruop = groups?.find(g => g.ruleGroupId === rg.id && g.sapAccount === rg.sapAccount && g.unit === rg.unit);
            if (!currentGruop) return null;

            const cellId = 'c' + personCode + '_' + rg.id;

            return (
                <>
                    {currentGruop.hasCorrections && <>
                        <FaRegBookmark className="correction-icon" id={cellId} />
                        <ToolTipItem text="Value has some corrections, go to daily view for more details" placement="top" targetId={cellId} />
                    </>}
                    {currentGruop.amount?.toFixed(2)}
                </>
            );
        };
    }

    private _renderFooterWrapper(rg: RuleGroupItem) {
        return (cellData: CellData<ISalaryMonthViewModelResponse>) => {
            const { dataSource } = cellData;

            if (!dataSource) return null;

            let val = 0;

            for (let i = 0; i < dataSource.length; i++) {
                const item = dataSource[i];
                const v = item.groups?.find(g => g.ruleGroupId === rg.id && g.sapAccount === rg.sapAccount);
                if (typeof v?.amount !== 'number') continue;
                val += v.amount;
            }

            return <>{val ? val.toFixed(2) : '-'}</>;
        };
    }

    private _renderHeaderWrapper = (rg: RuleGroupItem) => () => {
        return (
            <>
                {rg.importType ?? rg.name}
                <br />
                <small>{rg.sapAccount}, {rg.unit === 'Money' ? 'CHF' : rg.unit}</small>
            </>
        );
    };

    private _cellDoubleClickHandler(rg: RuleGroupItem) {
        const { onCellDoubleClick } = this.props;
        return (cellInfo: CellInfo<ISalaryMonthViewModelResponse>) => {
            onCellDoubleClick?.(cellInfo, rg);
        };
    }
}

export { SalaryTable };