import { ERROR_INVALID_FORMAT } from '@AppConstants';
import { InputWithError } from '@Components';
import { Validators } from '@Helpers';
import React, { useEffect, useRef, useState } from 'react';
import './money-cell.scss';

type MoneyCellProps = {
	initialValue?: number;
	disabled?: boolean;
	onChange: (value: number) => void;
};

export const MoneyCell: React.FC<MoneyCellProps> = ({ initialValue, onChange, disabled }) => {

	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [value, setValue] = useState<string>('');
	const inputEl = useRef<HTMLInputElement>(null);

    function format(value?: number): string {
        let res = (value !== undefined && value !== null) ? '' + value.toFixed(2) : '';
        if (res === '0.00') {
            res = '';
        };
        return res;
    }

	useEffect(() => {
        let res = format(initialValue);
		setValue(res);	
	}, [initialValue]);

	const blurHandler = () => {
		if (value !== format(initialValue) && !validationErrors.length) {
			const v = format ? format(+value) : value;
			setValue(v);
			onChange(+v);
		}
	};

	const keyDownHandler = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			inputEl.current?.blur();
		} else if (e.key === 'Escape') {
			setValue(format(initialValue));
			inputEl.current?.blur();
		}
	};

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = Validators.isNumberFractionalPartIsLessThanOrEqual(+e.target.value, 2, true);
        const errors = isValid ? [] : [ERROR_INVALID_FORMAT];
		setValue(e.target.value);
		setValidationErrors(errors);
	};

	return (
		<InputWithError
			innerRef={inputEl}
			className="money-cell"
			value={value}
			onChange={changeHandler}
			onBlur={blurHandler}
			onKeyDown={keyDownHandler}
			disabled={disabled}
			validationErrors={validationErrors}
		/>
	);
};