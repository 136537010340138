/* eslint-disable */
import React, { CSSProperties } from 'react';
import { ColumnFiltersMap, ColumnSortModel } from '@Pages/Home/Components';

export type DataGridProps<T> = {
    dataSource: Array<T>;
    gridName?: string;
    className?: string;
    virtualized?: boolean;
    virtualRowHeight?: number;
    useScrollbarIndent?: boolean;
    headerHeight?: number;
    onScroll?: (scrollTop: number) => void;
    scrollTop?: number;
    selectedRow?: T;
    highlightedRow?: T;
    totalRow?: boolean;
    groupingKey?: keyof T;
    onRowClick?: (row: T) => void;
    onRowDoubleClick?: (row: T) => void;
    onRowHover?: (row?: T) => void;
    onRowRender?: (args: TableRowRenderArgs<T>) => void;
    onGroupingRowRender?: (args: TableRowRenderArgs<T>) => void;
    onResizeHeader?: (width?: number, height?: number) => void;
    onOrderGroup?: (a: T, b: T) => number;
    onChangeSort?: (columnSort: ColumnSortModel | null) => void;
    onChangeFilters?: (columnFilters: ColumnFiltersMap) => void;
    sizeConfig?: SizeConfig;
    loading?: boolean;
    allowColumnResize?: boolean;
    allowHeaderFilters?: boolean;
} & DataGridAppearanceConfig;

export type DataGridAppearanceConfig = {
	// showColumnBorders?: boolean;
	// showRowBorders?: boolean;
	// showOuterBorder?: boolean;
	rowAlternation?: boolean;
};

export type TableRowRenderArgs<T> = RowRenderArgs & {
    item: T;
};

export type RowRenderArgs = {
    child: React.ReactNode;
    rowAttributes: React.HTMLAttributes<HTMLDivElement> & {[key: string]: unknown };
};

export type RowProps = {
    style?: React.CSSProperties;
    classNamePreffix?: string;
    selected?: boolean;
    highlighted?: boolean;
    onClick?: () => void;
    onRowDoubleClick?: () => void;
    onHover?: () => void;
    onRender?: (args: RowRenderArgs) => void;
};

export type SizeConfig = {
    height?: number;
    width?: number;
};

export type ColumnProps<TItem, TValue> = {
    className?: string;
    caption?: string;
    dataField?: keyof TItem;
    item?: TItem;
    dataSource?: TItem[]; 
    onDoubleClick?: (cellInfo: CellInfo<TItem, TValue>) => void;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
} & CustomizedCellRenderProps<TItem, TValue>;

export type CellInfo<TItem, TValue = unknown> = {
    caption?: string;
    dataField?: keyof TItem;
    value?: TValue;
    item?: TItem;
    isTotalRow?: boolean;
    isHeaderRow?: boolean;
};

export enum DataFieldType {
    Time
};

export type GetDataField<TItem = any> = (row: TItem) => string | number | undefined;

export type CustomizedCellRenderProps<TItem, TValue = unknown> = {
    isTotalRow?: boolean;
    isHeaderRow?: boolean;
    dataFieldType?: DataFieldType;
    getDataField?: GetDataField<TItem>;
    cellRender?: ICellRender<TItem, TValue>;
    headerCellRender?: ICellRender<TItem>;
    footerCellRender?: ICellRender<TItem>;
    onFooterCellPrepare?: IFooterCellPrepare;
};

export type OldCellProps<TItem, TValue = unknown> = {
    key: string;
    className?: string;
    caption?: string;
    dataField?: keyof TItem;
    item?: TItem;
    dataSource: TItem[];
    onDoubleClick?: (cellInfo: CellInfo<TItem, TValue>) => void;
} & CellStyles & CustomizedCellRenderProps<TItem, TValue>;

export type ColumnSize = {
    name: string;
    width?: number;
    minWidth?: number;
};

export type CellStyles = {
    width?: number;
    minWidth?: number;
    maxWidth?: number;
};

export type ResizableCellProps = {
    resizable?: boolean;
    column?: ColumnSize;
    columns: ColumnSize[];
    onUpdateColumnWidth?: (newWidth: number) => void;
};

export type CellProps<TItem, TValue = unknown> = {
    cellData: CellData<TItem, TValue>;
    cellAttributes: CellAttributes;
    cellRender: ICellRender<TItem, TValue>;
} & ResizableCellProps;

export type CellData<TItem, TValue = unknown> = {
    dataSource: TItem[];
    caption?: string;
    row?: TItem;
    dataField?: keyof TItem;
    value: TValue; // TODO : temp?
};

export type CellOptions = {
  className: string;
  hint?: string;
};

// TODO: restrict?
export type CellAttributes = {
    cssClass?: string;
    className?: string;
    style?: CSSProperties;
    htmlAttributes?: React.HTMLAttributes<HTMLDivElement> & {[key: string]: unknown };
    // onDoubleClick?: () => (cellInfo: CellInfo<TItem, TValue>) => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
} & CellStyles;

export type ICellRender<TItem, TValue = unknown> = (cellData: CellData<TItem, TValue>, cellOptions: CellOptions) => JSX.Element | null;
export type IFooterCellPrepare = () => React.CSSProperties | undefined;

export type DefaulCellTemptProps<TItem, TValue> = {
	cellRender: ICellRender<TItem, TValue>;
};