import * as React from 'react';
import {observer} from 'mobx-react';
import {Col, Row} from 'reactstrap';

import {FormDatePicker, FormInput, FormLabel, FormTextarea, IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow, NotificationHandler} from '@Components';

import {FormModel} from './SalaryPeriodItemFormModel';
import {ApiClient, SalaryItemCreateModel, SalaryPeriodResponseItem} from '@Models';
import {DateTimeService} from '@Services';

type CreateSalaryPeriodItemDialogProps = {
    personId: string;
    period: SalaryPeriodResponseItem;
    importTypeId: number;
    title: string;
};

@observer
export class CreateSalaryPeriodItemDialog extends React.Component<CreateSalaryPeriodItemDialogProps, {}> implements IModalDialogContent<void> {
    private _minDate: Date = this.props.period.periodDateFrom;
    private _maxDate: Date = DateTimeService.addDays(this.props.period.periodDateTo, 1);
    private _form: FormModel = new FormModel(this._minDate);
    private _window: ModalWindow<void>;
    private _apiClient = new ApiClient();


    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        this._window = window;

        return {
            title: 'Create ' + this.props.title,
            buttons: [{
                title: 'Close',
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            },
                {
                    title: 'Save',
                    type: ModalButtonType.Save,
                    isDisabled: !this._form.isFormValid,
                    onClick: this._submit
                }
            ],
            width: '500px',
            bodyClassName: 'create-rule-dialog'
        };
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <FormLabel formModel={this._form} name="date"/>
                        <FormDatePicker name="date" formModel={this._form} minDate={this._minDate} maxDate={this._maxDate} showToday={false}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormLabel formModel={this._form} name="amount"/>
                        <FormInput<FormModel>
                            name="amount"
                            formModel={this._form}
                            type="number"
                            step="0.01"
                            onBlur={() => {
                                this._form.amount = Number(this._form.amount).toFixed(2);
                                this._form.validate();
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Duty Comment
                        <FormTextarea name="comments" formModel={this._form}/>
                    </Col>
                </Row>
            </>
        );
    }

    private _submit = async () => {
        const {importTypeId, period, personId} = this.props;
        const {date, amount, comments} = this._form;

        if (!this._form.validate()) {
            return;
        }

        const updateModel: SalaryItemCreateModel = {
            date: DateTimeService.parseUiDate(date),
            amount: +amount,
            comments: comments,
            importTypeId: importTypeId,
            periodId: period.id,
            personId: personId
        };

        try {
            await this._apiClient.salarySalaryItemPost(updateModel);
            NotificationHandler.showSuccess('Salary item saved succesfully');
            this._window?.close(ModalButtonType.Save);
        } catch (e) {
            NotificationHandler.showError('Salary item is not saved');
        }
    };
}
