import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ApiClient, RuleGroupItem, SalaryDailyViewModelResponseItem, SalaryItemUnit } from '@Models';
import { ApiUrls } from '@AppConstants';

import { CompletionType, PromiseCompletion } from '@Helpers';
import { IAjaxOptions } from '@Services';

export class DutyTableStore {
    @observable private _originDataSource: SalaryDailyViewModelResponseItem[] = [];
    @observable.ref public groups: RuleGroupItem[] = [];
    private _loader: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);
    private _apiClient = new ApiClient();

    constructor() {
        makeObservable(this);
    }

    @action.bound
    async loadData(periodId: number, personId: string) {
        const params = { periodId, personId };
        const options: IAjaxOptions = { cancelDuplicatedRequests: true,  requestKey: ApiUrls.SalaryDayUrl };

        const { data: { days, groups } } = await this._loader.subscribe(this._apiClient.salaryDayByPeriodIdByPersonIdGet(params, options));;

        runInAction(() => {
            this._originDataSource = days;
            this.groups = groups;
        });
    }

    @computed
    public get loading() {
        return this._loader.isPending;
    }

    async updateCorrrection(periodId: number, personId: string, date: Date, ruleGroupId: number, sapAccount: number, unit: SalaryItemUnit, correctionAmount: number, comment: string, onSubscribeBeforeUpdate: (updatePromise: Promise<unknown>) => void) {
        const promise = this._apiClient.salaryDayByPeriodIdByPersonIdByDateByRuleGroupIdBySapAccountByUnitPut(
            periodId, personId, date, ruleGroupId, sapAccount, unit, {
                amount: correctionAmount,
                comment: comment
            });
        onSubscribeBeforeUpdate(promise);
        const { data } = await promise;
        
        this._updateItem(data);
    }

    async deleteCorrrection(periodId: number, personId: string, date: Date, ruleGroupId: number, sapAccount: number, unit: SalaryItemUnit) {
        const { data } = await this._apiClient.salaryDayByPeriodIdByPersonIdByDateByRuleGroupIdBySapAccountByUnitDelete({
            periodId: periodId,
            personId: personId,
            date: date,
            ruleGroupId: ruleGroupId,
            sapAccount: sapAccount,
            unit: unit
        });
        this._updateItem(data);
    }
    
    @action.bound
    private _updateItem(data: SalaryDailyViewModelResponseItem){
        runInAction(() => {
            if (this._originDataSource){
                const index = this._originDataSource.findIndex(x => x.date.getTime() === data.date.getTime());
                if (index !== -1){
                    this._originDataSource[index] = data;
                }
            }
        });
    }

    @computed
    public get dataSource() {
        return this._originDataSource;
    }
}