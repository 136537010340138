import { Input } from '@Components';
import React, {Component} from 'react';
import './text-filter.scss';

type TextFilterProps = {
    value: string;
    setValue: (value: string) => void;
};

export class TextFilter extends Component<TextFilterProps, {}> {
    render() {
        const { value, setValue } = this.props;

        return (
            <div className="text-filter">
                <Input
                    type="text"
                    placeholder="Search"
                    value={value}
                    onChange={e => setValue(e.target.value || '')}
                />
            </div>
        );
    }
}