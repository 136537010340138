import React, {Component, ReactNode} from 'react';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import ReactResizeDetector from 'react-resize-detector';
import { FaFilter } from 'react-icons/fa';

import {Cell, Row, ScrollerSettings, VirtualScroll} from '@Components';
import {Loading} from '@Components/Loading/Loading';
import { CellAttributes, CellData, OldCellProps, ColumnSize, DataGridProps, ICellRender, TableRowRenderArgs, CustomizedCellRenderProps, DataFieldType, GetDataField } from '../gridtypes';
import {cellRenderer, defaultHeaderCellRender} from '../Cell/CellRenderer';

import { DateTimeService } from '@Services';
import { FilterTableStore, FilterPopup, FilterValue, ColumnFilters, ColumnSort } from '@Pages/Home/Components';
import './grid.scss';

function defaultComparer<T>(a: T, b: T, key: keyof T): number {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }
    return 0;
}

type DataGridState = {
    columns: ColumnSize[];
};

@observer
export class Grid<T> extends Component<DataGridProps<T>, DataGridState> {
    private _tableBodyRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _tableHeaderRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _scrollBarWidth: number = 0;
    private _tableFilterStore: FilterTableStore;
    @observable private _originDataSource: T[] | null = null;

    constructor(props: DataGridProps<T>) {
        super(props);
        makeObservable(this);
        this.state = {
            columns: []
        };
        this._tableFilterStore = new FilterTableStore(props.gridName);
    }

    componentDidMount() {
        this._scrollBarWidth = this._getScrollBarWidth();
    }

    componentDidUpdate() {
        if (!this.state.columns.length) {
            const columns = React.Children.toArray(this.props.children);

            if (columns.length) {
                const initialSizes = columns.map((col, i) => {
                    return {name: 'col' + i, width: undefined};
                });
                this.setState({columns: initialSizes});
            }
        }
        if (this._originDataSource === null && !!this.props.dataSource.length) {
            this._originDataSource = this.props.dataSource;
        }
    }

    render() {
        const { className, virtualized, onRowHover, totalRow, sizeConfig, allowHeaderFilters } = this.props;
        const cls = ['data-grid'];

        if (className) cls.push(className);
        const { columnFilters, columnSort } = this._tableFilterStore;
        const hasActiveFilters = allowHeaderFilters && (columnFilters.size || columnSort !== null);

        let style: React.CSSProperties = {};

        if (sizeConfig) {
            const {width, height} = sizeConfig;
            if (width) {
                style = Object.assign({width}, style);
            }

            if (height) {
                style = Object.assign({height}, style);
            }
        }

        return (
            <div className='position-relative'>
                {hasActiveFilters && <FaFilter className="clear-filters-icon" title="Clear filters" onClick={this._onClearFilters} />}
                <div className={cls.join(' ')} onMouseLeave={() => onRowHover?.()} style={style}>
                    {this._renderHeader()}
                    {virtualized ? this._renderVirtualizedBody() : this._renderBody()}
                    {totalRow && this._renderTotal()}
                </div>
            </div>
        );
    }

    get virtualRowHeight() {
        return this.props.virtualRowHeight ?? 25;
    }

    private _renderHeader() {
        const { headerHeight} = this.props;
        const columns = this._renderColumns({isHeaderRow: true});

        const clsThead = ['thead'];

        const rowPaddingRight = this._getRowRightOffset();

        return (
            <ReactResizeDetector handleHeight onResize={this.props.onResizeHeader} targetRef={this._tableHeaderRef}>
                <div ref={this._tableHeaderRef} className={clsThead.join(' ')} style={{ minHeight: headerHeight || 'auto' }}>
                    <Row style={{ paddingRight: rowPaddingRight }}>
                        {columns}
                    </Row>
                </div>
            </ReactResizeDetector>
        );
    }

    private _renderTotal() {
        const columns = this._renderColumns({ isTotalRow: true, dataSource: this._dataSource });

        const cls = [];

        cls.push('total-row');

        const rowPaddingRight = this._getRowRightOffset();

        return (
            <div className={cls.join(' ')}>
                <Row style={{ paddingRight: rowPaddingRight }}>
                    {columns}
                </Row>
            </div>
        );
    }

    private _renderBody() {
        const { loading, virtualized, groupingKey, onGroupingRowRender, onRowRender, onOrderGroup } = this.props;

        const cls = ['tbody'];

        cls.push('customized-scroll');
        if (virtualized) {
            cls.push('scroll-indent');
        }

        let rows: JSX.Element[] = [];
        if (groupingKey) {
            const items = this._dataSource.slice(0);
            items.sort((a, b) => {
                let result = defaultComparer<T>(a, b, groupingKey);
                if (onOrderGroup && result === 0){
                    return onOrderGroup(a, b);
                }
                return result;
            });
            let currentGroupItem: unknown | undefined;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const groupValue = item[groupingKey];
                if (groupValue !== currentGroupItem) {
                    currentGroupItem = groupValue;

                    const attrs: React.HTMLAttributes<HTMLDivElement> & { [key: string]: unknown } = {
                        className: 'grouping-row',
                        key: `${String(groupingKey)}_${currentGroupItem}`
                    };

                    let args: TableRowRenderArgs<T> = {
                        child: null,
                        rowAttributes: attrs,
                        item: item
                    };

                    onGroupingRowRender?.(args);

                    rows.push(React.createElement('div', attrs, args.child ?? <>{currentGroupItem as unknown as ReactNode}</>));
                }
                rows.push(
                    <Row key={'g' + i}>
                        {this._renderColumns({ item })}
                    </Row>
                );
            }
        } else {
            rows.push(...this._dataSource.map((item, i) => {
                return (
                    <Row
                        key={i}
                        onRender={(args) => {
                            const rowArgs: TableRowRenderArgs<T> = {
                                item: item,
                                rowAttributes: args.rowAttributes,
                                child: args.child
                            };
                            onRowRender?.(rowArgs);
                        }}>
                        {this._renderColumns({ item })}
                    </Row>
                );
            }));
        }

        return (
            <div className={cls.join(' ')}>
                {rows}
                {!this._dataSource.length && <div className="no-data-paceholder">No data loaded</div>}
                <Loading loading={loading}/>
            </div>
        );
    }

    private _renderVirtualizedBody() {
        const { onScroll, scrollTop, loading } = this.props;
        if (!onScroll) {
            return;
        }

        return (
            <ReactResizeDetector handleHeight targetRef={this._tableBodyRef}>
                {({height}) => {

                    const virtualSettings: ScrollerSettings = {
                        itemHeight: this.virtualRowHeight,
                        scrollTop: (this._dataSource.length && scrollTop) || 0,
                        virtualHeight: this._dataSource.length * this.virtualRowHeight,
                        viewportHeight: height || 0
                    };

                    return (
                        <div className="tbody" ref={this._tableBodyRef}>
                            <VirtualScroll
                                dataSource={this._dataSource}
                                settings={virtualSettings}
                                row={this.rowTemplate}
                                onScroll={onScroll}
                            />
                            <Loading loading={loading}/>
                        </div>
                    );

                }}
            </ReactResizeDetector>
        );
    }

    private rowTemplate = (item: T) => {
        const { selectedRow, onRowClick, onRowDoubleClick, onRowHover, onRowRender, highlightedRow } = this.props;
        const columns = this._renderColumns({item});
        const index = this._dataSource.indexOf(item);
        const highlighted = item === highlightedRow;
        const selected = item === selectedRow;
        const rowStyle = this._getRowStyle(highlighted, selected);

        return (
            <Row
                key={index}
                style={rowStyle}
                classNamePreffix="item"
                selected={selected}
                highlighted={highlighted}
                onClick={() => onRowClick?.(item)}
                onRowDoubleClick={() => onRowDoubleClick?.(item)}
                onHover={() => onRowHover?.(item)}
                onRender={(args) => {
                    const rowArgs: TableRowRenderArgs<T> = {
                        item: item,
                        rowAttributes: args.rowAttributes,
                        child: args.child
                    };
                    onRowRender?.(rowArgs);
                    args.child = rowArgs.child;
                    args.rowAttributes = rowArgs.rowAttributes;
                }}
            >
                {columns}
            </Row>
        );
    };

    private _renderColumns(rowConfig: Partial<OldCellProps<T>>) {
        const {children} = this.props;
        const columns = React.Children.toArray(children);

        return React.Children.map(columns, (child, index) => {
            const columnProps = (child as JSX.Element).props;
            const cellProps = Object.assign({key: 'col' + index}, columnProps, rowConfig);
            return this._renderCell(cellProps);
        });
    }

    _renderCell(cellProps: OldCellProps<T>) {
        const { allowColumnResize, allowHeaderFilters } = this.props;
        const { key, item, dataField, caption, onDoubleClick, dataSource, className } = cellProps;
        const {isHeaderRow, isTotalRow, onFooterCellPrepare} = cellProps;
        const value = item && dataField ? item[dataField] as unknown : void 0 as unknown;

        // TODO: check styles
        const attrs: React.HTMLAttributes<HTMLDivElement> & { [key: string]: unknown } = {
            className: '',
            style: {}
        };

        if (className) {
            attrs.className = className;
        }

        if (onDoubleClick) {
            attrs.onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
                onDoubleClick({caption, dataField, item, value, isHeaderRow, isTotalRow});
            };
        }

        if (isTotalRow && onFooterCellPrepare) {
            attrs.style = onFooterCellPrepare?.();
        }

        const cellAttrs: CellAttributes = {
            htmlAttributes: attrs,
            minWidth: cellProps.minWidth,
            maxWidth: cellProps.maxWidth,
            className: attrs.className,
            onDoubleClick: attrs.onDoubleClick
        };

        const cellData: CellData<T> = {
            dataField,
            dataSource,
            value,
            caption,
            row: item
        };

        const cr = this._getCellRender(cellProps);
        const col = this.state.columns.find(c => c.name === key);
        const showColumnFilters = isHeaderRow && allowHeaderFilters;

        return (
            <Cell
                resizable={allowColumnResize && isHeaderRow}
                columns={this.state.columns}
                column={col}
                cellAttributes={cellAttrs}
                cellData={cellData}
                cellRender={cr}
                onUpdateColumnWidth={this._handleColumnWidthChange(col)}
            >
                {showColumnFilters && this._renderFilterPopup(cellProps)}
            </Cell>
        );
    }

    private _renderFilterPopup(cellProps: OldCellProps<T>) {
        const { dataField: cellDataFiled, getDataField } = cellProps;
        const dataField = cellDataFiled as string || cellProps.key;
        const filterValues = this._getDefaultFilterOptions(cellProps);
        const { columnSort, columnFilters, getIsFilterActive } = this._tableFilterStore;
        const isFilterActive = getIsFilterActive(dataField);

        return (
            <FilterPopup
                dataField={dataField}
                filterOptions={filterValues}
                isFilterActive={isFilterActive}
                columnSort={columnSort}
                columnFilters={columnFilters}
                onChangeSort={(sortType) => this._changeSortHandler(sortType, dataField, getDataField)}
                onChangeFilters={(filterValue) => this._changeFiltersHandler(filterValue, dataField, getDataField)}
            />
        );
    }

    private _changeSortHandler = (sortType: ColumnSort, dataField: string, getDataField?: GetDataField) => {
        this._tableFilterStore.setColumnSort(sortType, dataField, getDataField);
        this.props.onChangeSort?.(this._tableFilterStore.columnSort);
    };

    private _changeFiltersHandler = (filterValue: FilterValue, dataField: string, getDataField?: GetDataField) => {
        this._tableFilterStore.setColumnFilters(filterValue, dataField, getDataField);
        this.props.onChangeFilters?.(this._tableFilterStore.columnFilters);
    };

    private _onClearFilters = () => {
        this._tableFilterStore.clearFilters();
        this.props.onChangeSort?.(this._tableFilterStore.columnSort);
        this.props.onChangeFilters?.(this._tableFilterStore.columnFilters);
    };

    private _getDefaultFilterOptions = (cellProps: OldCellProps<T>) => {
        if (!this._originDataSource) return;

        const { dataField, dataFieldType, getDataField } = cellProps;
        const values = [];

        for (const item of this._originDataSource) {
            const value = (dataField && item[dataField]) || getDataField?.(item);
            if (value) {
                if (typeof value === 'string' || typeof value === 'number') {
                    values.push(value);
                }
                if (Array.isArray(value)) {
                    value.forEach(v => {
                        if (typeof v === 'string' || typeof value === 'number') {
                            values.push(v);
                        }
                    });
                }
            }
        }

        const filteredAndSortedValues = [...new Set(values)]
            .map(v => {
                if (dataFieldType === DataFieldType.Time && typeof v === 'number') {
                    return { name: DateTimeService.toUiSeconds(v), value: v };
                }

                return { name: v.toString(), value: v };
            })
            .sort((a, b) => {
                if (a.value > b.value) return 1;
                if (b.value > a.value) return -1;
                return 0;
            });

        return filteredAndSortedValues;
    };

    private get _dataSource() {
        return this._applyFilters(this.props.dataSource);
    }

    private _applyFilters(dataSource: T[]) {
        const { columnFilters, columnSort } = this._tableFilterStore;
        let filteredList = [...dataSource];

        if (columnFilters.size) {
            const byColumn = ((item: T) => {
                const columnFiltersArray = Array.from(columnFilters);
                return columnFiltersArray.every(([key, { filterValues: filters, getDataField }]) => {
                    const value = item[key as keyof T] || getDataField?.(item);

                    if (filters.length === 1 && filters.includes(ColumnFilters.WithValues)) {
                        return !!value;
                    }

                    if (filters.length === 1 && filters.includes(ColumnFilters.WithoutValues)) {
                        return !value;
                    }

                    if (filters.length >= 1 && !filters.includes(value!)) {
                        return false;
                    }

                    return true;
                });
            });
            filteredList = filteredList.filter(byColumn);
        }

        if (columnSort) {
            filteredList.sort((d1, d2) => {
                const { dataField, getDataField } = columnSort;

                const d1Value = d1[dataField as keyof T] || getDataField?.(d1);
                const d2Value = d2[dataField as keyof T] || getDataField?.(d2);

                if (columnSort.sortType === ColumnSort.ASC && d1Value && d2Value) {
                    if (d1Value > d2Value) return 1;
                    if (d2Value > d1Value) return -1;
                    return 0;
                }

                if (columnSort.sortType === ColumnSort.DESC && d1Value && d2Value) {
                    if (d1Value > d2Value) return -1;
                    if (d2Value > d1Value) return 1;
                    return 0;
                }

                if (d1Value && !d2Value) {
                    return -1;
                }

                if (d2Value && !d1Value) {
                    return 1;
                }

                return 0;
            });
        }

        return filteredList;
    }

    private _handleColumnWidthChange(column?: ColumnSize) {
        return (width: number) => {
            if (column) {
                column.width = width;
                this.forceUpdate();
            } else {
                this.setState({columns: [...this.state.columns, {name: 'col', width}]});
            }
        };
    }

    private _getCellRender(cellProps: CustomizedCellRenderProps<T>): ICellRender<T, unknown> {
        const {isHeaderRow, isTotalRow, headerCellRender, footerCellRender, cellRender} = cellProps;

        if (isHeaderRow) {
            return !!headerCellRender ? headerCellRender : defaultHeaderCellRender;
        }

        if (isTotalRow && footerCellRender) {
            return footerCellRender;
        }

        if (!isHeaderRow && !isTotalRow && cellRender) {
            return cellRender;
        }

        return cellRenderer;
    };

    private _getRowStyle(highlighted: boolean, selected?: boolean): React.CSSProperties {
        const style: React.CSSProperties = {};
        if (this.props.virtualized) {
            style.height = this.props.virtualRowHeight;
        }
        if (selected) style.backgroundColor = '#ffc107F0';
        if (highlighted) style.backgroundColor = '#ffc10750';
        return style;
    }

    private _getScrollBarWidth() {
        const el = document.createElement('div');

        el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
        el.classList.add('customized-scroll');

        document.body.appendChild(el);

        const width = el.offsetWidth - el.clientWidth;

        el.remove();

        return width;
    }

    private _getRowRightOffset() {
        if (!this.props.useScrollbarIndent) return;

        const scrollBarWidth = this._scrollBarWidth;
        const borderWidth = 1;

        return scrollBarWidth + borderWidth;
    }
}