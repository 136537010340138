import React from 'react';
import './filter-button.scss';

export type FilterButtonProps = {
    personFunction?: string;
    className?: string;
    selected?: boolean;
    onClick?: (e: React.MouseEvent) => void;
};

export type FilterButtonItem = {
    value: string;
    label: string;
};

export const FilterButton: React.FC<FilterButtonProps> = ({ children, onClick, selected, className }) => {
    const css = ['filter-button', className];
    if (selected) css.push('selected');

    return (
        <div className={css.join(' ')} onClick={onClick}>
            {children}
        </div>
    );
};
