import React from 'react';
import { Label } from 'reactstrap';
import { Checkbox } from '@Components';
import './functionary-filter.scss';

type FunctionaryFilterProps = {
    checked: boolean;
    toggle: () => void;
};

export class FunctionaryFilter extends React.Component<FunctionaryFilterProps, {}> {
    render() {
        const { checked, toggle } = this.props;

        return (
            <div className="functionary-filter">
                <Label>
                    <Checkbox checked={checked} onChange={toggle} />
                    Functionary Only
                </Label>
            </div>
        );
    }
}
