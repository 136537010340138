import React from 'react';
import { observer } from 'mobx-react';
import { ValueType } from 'react-select';
import { Activity, ActivityPickerStore } from './ActivityPickerStore';
import { MultiSelect } from '@Components';

import './activity-picker.scss';
import { PersonActivitiesInfo } from '@Models';

type ActivityPickerProps = {
	selectedValues: string[];
	periodId?: number;
	invalid?: boolean;
	disabled?: boolean;
	personActivitiesInfos?: PersonActivitiesInfo[];
	onBlur?: () => void;
	onChange?: (contract: Activity[]) => void;
};

@observer
export class ActivityPicker extends React.PureComponent<ActivityPickerProps> {
	private _store: ActivityPickerStore;

	constructor(props: ActivityPickerProps) {
		super(props);

		this._store = new ActivityPickerStore(props.selectedValues);
		this._handleSelectChange = this._handleSelectChange.bind(this);
		this._handleMenuOpen = this._handleMenuOpen.bind(this);
	}

	render() {
		const { invalid, disabled } = this.props;
		const { activities, selectedActivities } = this._store;

		return (
			<div className="activity-picker">
				<MultiSelect<Activity>
					options={activities}
					onChange={this._handleSelectChange}
					onMenuOpen={this._handleMenuOpen}
					className="activity-picker-dropdown"
					value={selectedActivities}
					isMulti
					hideSelectedOptions={false}
					closeMenuOnSelect={false}
					invalid={invalid}
					isDisabled={disabled}
					placeholder='Select duty...'
				/>
			</div>
		);
	}

	private _handleMenuOpen() {
		const { periodId, personActivitiesInfos: existingPersonActivitiesInfos } = this.props;
		if (!periodId) return;
		if (existingPersonActivitiesInfos) {
			this._store.setupActivities(existingPersonActivitiesInfos);
		} else {
			void this._store.loadActivities(periodId);
		}
	}

	private _handleSelectChange = (selectedOptions: ValueType<Activity, true>) => {
		const options = [...selectedOptions];
		this._store.setSelectedActivities(options);
		this.props.onChange?.(options);
	};
}