import React, {Component} from 'react';
import './contract-filter.scss';

import { FilterButton, FilterButtonProps, FilterButtonItem } from '../FilterButton/FilterButton';

type ContractFilterProps = {
    contracts: FilterButtonItem[];
    selectedContracts: string[];
    setContractFilter: (contract: string[]) => void;
};

export class ContractFilter extends Component<ContractFilterProps, {}> {
    render() {
        const { contracts, selectedContracts, setContractFilter  } = this.props;

        return (
            <div className="contract-filter">
                {contracts.map(contract => {
                    const isSelected = selectedContracts.length ? selectedContracts.some(sc => sc === contract.value) : contract.label === 'All';
                    const handleClick = () => setContractFilter([contract.value]);
                    return <ContractButton key={contract.label} selected={isSelected} onClick={handleClick}>{contract.label}</ContractButton>;
                })}
            </div>
        );
    }
}

const ContractButton: React.FC<FilterButtonProps> = ({ children, onClick, selected }) => {
    return (
        <FilterButton className="contract-button" onClick={onClick} selected={selected}>
            {children}
        </FilterButton>
    );
};