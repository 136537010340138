import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import ContextMenu from '@Components/ContextMenu/ContextMenu';
import { ContextMenuItem } from '@Components/ContextMenu/ContextMenuItem';
import { ApiClient, PermissionType, SalaryPeriodResponseItem, SalaryPeriodStatus } from '@Models';
import { securityService } from '@Services';
import { ContextMenuSeparator } from '@Components/ContextMenu';

type PersonContextMenuProps = {
    container: React.RefObject<HTMLElement>;
    timePeriod: SalaryPeriodResponseItem | null;
    onPersonImported?: (personId: string) => void;
    onProgress: (personId: string, operation: Promise<unknown>) => void;
};

@observer
export default class PersonContextMenu extends React.Component<PersonContextMenuProps, {}>{
    private _apiClient = new ApiClient();

    render() {
        return (
            <ContextMenu container={this.props.container}>
                {(element) => this._renderMenu(element)}
            </ContextMenu>
        );
    }

    private _renderMenu(target: HTMLElement): React.ReactElement<HTMLElement> | null {
        const personIdTarget = target.closest('[data-menu-person-id]') as HTMLElement | null;
        const personId = personIdTarget && personIdTarget.getAttribute('data-menu-person-id');
        const periodId = this.props.timePeriod?.id;
        const periodStatus = this.props.timePeriod?.status;

        if (!personId || !periodId) return null;

        const isDisabled = !securityService.hasPermission(PermissionType.ProcessImportPeriod) || periodStatus === SalaryPeriodStatus.Released || (periodStatus === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod));

        const currentUrl = new URL(window.location.href);
        const searchValue = 'vasa';
        const replaceValue = 'crewpage';
        const modifiedUrl = currentUrl.href.replace(searchValue, replaceValue);
        const fdrUrl = `${modifiedUrl}crew-portal/personneldata?impersonateAsPerson=${personId}`;
        return (
            <>
                <ContextMenuItem title="Go to FDR page" href={fdrUrl} hrefTarget="_blank" />
                <ContextMenuSeparator />                
                <ContextMenuItem title="Re-Import Duties" onClick={() => this._reImportDuties(personId, periodId)} disabled={isDisabled} />
                <ContextMenuItem title="Re-Calculate Duties" onClick={() => this._reCalculateDuties(personId, periodId)} disabled={isDisabled} />
            </>
        );
    }

    @action.bound
    private async _reImportDuties(personId: string, periodId: number) {
        if (!personId) return;

        const params = {
            periodId: periodId,
            personId: personId
        };

        const operation = this._apiClient.dutyPeriodByPeriodIdPersonByPersonIdReimportPut(params);

        this.props.onProgress(personId, operation);

        await operation;

        this.props.onPersonImported?.(personId);
    }

    @action.bound
    private async _reCalculateDuties(personId: string, periodId: number) {
        if (!personId) return;

        const params = {
            periodId: periodId,
            personId: personId
        };

        const operation = this._apiClient.dutyPeriodByPeriodIdPersonByPersonIdRecalculatePut(params);

        this.props.onProgress(personId, operation);

        await operation;

        this.props.onPersonImported?.(personId);
    }
}
