import { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { CellData, Column, Grid, ModalButtonType } from '@Components';
import { ImportedSalaryTableStore } from './ImportedSalaryTableStore';
import { ImportTypeModel, PermissionType, SalaryItemViewModel, SalaryPeriodResponseItem, SalaryPeriodStatus } from '@Models';
import { DateTimeService, securityService } from '@Services';
import { modalService } from '@Components/Modal/ModalService';
import { CreateSalaryPeriodItemDialog } from '../CreateSalaryPeriodItemDialog/CreateSalaryPeriodItemDialog';
import { InputCell } from '@Pages/RuleEditor/Components/InputCell/InputCell';
import { MoneyCell } from '@Pages/RuleEditor/Components/MoneyCell/MoneyCell';
import { FaTimes } from 'react-icons/fa';
import { ERROR_TO_LONG, ImportSalaryType, IMPORT_FINISHED } from '@AppConstants';

type ImportedSalaryTableProps = {
    classPreffix?: string;
    personId: string;
    period: SalaryPeriodResponseItem;
    importType: ImportTypeModel;
    onResizeHeader?: (width?: number, height?: number) => void;
    onChanged?: (personId: string) => void;
};

@observer
class ImportedSalaryTable extends Component<ImportedSalaryTableProps, {}> {
    private _store: ImportedSalaryTableStore;

    constructor(props: ImportedSalaryTableProps) {
        super(props);
        this._store = new ImportedSalaryTableStore();
    }

    componentDidMount() {
        document.addEventListener(IMPORT_FINISHED, this._handleImportFinish);
        void this._store.loadData(this.props.personId, this.props.period.id, this.props.importType.id);
    }

    componentDidUpdate(prevProps: ImportedSalaryTableProps) {
        if (prevProps.personId !== this.props.personId || prevProps.period.id !== this.props.period.id || prevProps.importType.id !== this.props.importType.id) {
            this._store.cancel();
            void this._store.loadData(this.props.personId, this.props.period.id, this.props.importType.id);
        }
    }

    componentWillUnmount() {
        document.removeEventListener(IMPORT_FINISHED, this._handleImportFinish);
        this._store.cancel();
    }

    private _handleImportFinish = () => {
        void this._store.loadData(this.props.personId, this.props.period.id, this.props.importType.id);
    };

    render() {
        const { onResizeHeader, importType } = this.props;
        const { dataSource, loader } = this._store;
        const permission = this._getPermission(importType);

        return (
            <>
                {permission && securityService.hasPermission(permission) &&
                    <Button
                        color="success"
                        size="sm"
                        style={{ marginBottom: 5 }}
                        onClick={this._createClickHandler}
                        disabled={this.props.period.status === SalaryPeriodStatus.Released || (this.props.period.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod))}
                    >
                        Create
                    </Button>
                }
                <Grid
                    dataSource={dataSource}
                    onResizeHeader={onResizeHeader}
                    loading={loader.isPending}
                >
                    <Column<SalaryItemViewModel, Date> dataField="date" caption="Date" cellRender={this._renderDateCell} minWidth={100} maxWidth={100} />
                    <Column dataField="amount" caption="Amount" cellRender={this._amountCellRender} maxWidth={150} />
                    <Column dataField="unit" caption="Unit" maxWidth={120} />
                    <Column dataField="comments" caption="Comments" cellRender={this._commentsCellRender} />
                    <Column caption="Actions" cellRender={this._renderActionCell} maxWidth={50} />
                </Grid>
            </>
        );
    }

    private _renderDateCell = (cellData: CellData<SalaryItemViewModel, Date>) => {
        const { value } = cellData;
        return <>{DateTimeService.toUiDate(value)}</>;
    };

    private _renderActionCell = (cellData: CellData<SalaryItemViewModel, Date>) => {
        const { row } = cellData;
        const { personId, importType, onChanged } = this.props;
        const permission = this._getPermission(importType);
        const isDisabled = !permission || !securityService.hasPermission(permission) || this.props.period.status === SalaryPeriodStatus.Released || (this.props.period.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod));
        return (
            <FaTimes
                className={'delete-button' + (isDisabled ? '-disabled' : '')}
                onClick={async () => {
                    if (isDisabled) return;
                    if (!await modalService.showConfirmation('Do you really want to remove item?', { color: 'danger' })) return;

                    await this._store.deleteItem(row!);
                    if (onChanged) {
                        onChanged(personId);
                    }
                }}
                title="Delete"
            />
        );
    };

    private _createClickHandler = async () => {
        const { period, personId, importType, onChanged } = this.props;
        const { button } = await modalService.show(CreateSalaryPeriodItemDialog, { period, personId, importTypeId: importType.id, title: importType.name });

        if (button === ModalButtonType.Save) {
            await this._store.loadData(personId, period.id, importType.id);
            if (onChanged) {
                onChanged(personId);
            }
            return;
        }
    };

    private _amountCellRender = (cellData: CellData<SalaryItemViewModel>) => {
        const { row } = cellData;
        const { updateItem } = this._store;
        const { importType, personId, onChanged } = this.props;
        const permission = this._getPermission(importType);

        const changeHandler = async (value: number) => {
            const modifiedItem = Object.assign({}, row);
            modifiedItem.amount = value;
            await updateItem(modifiedItem);
            if (onChanged) {
                onChanged(personId);
            }
        };

        // TODO: check
        // args.cellAttributes.className += ' picker';
        return (
            <MoneyCell
                initialValue={row!.amount}
                onChange={changeHandler}
                disabled={!permission || !securityService.hasPermission(permission) || this.props.period.status === SalaryPeriodStatus.Released || (this.props.period.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod))}
            />
        );
    };

    private _commentsCellRender = (cellData: CellData<SalaryItemViewModel>) => {
        const { row } = cellData;
        const { updateItem } = this._store;
        const { importType, personId, onChanged } = this.props;
        const permission = this._getPermission(importType);

        const changeHandler = async (value: string) => {
            const modifiedItem = Object.assign({}, row);
            modifiedItem.comments = value;
            await updateItem(modifiedItem);
            if (onChanged) {
                onChanged(personId);
            }
        };

        // TODO: check
        // args.cellAttributes.className += ' picker';
        return (
            <InputCell
                validate={value => value.length > 255 ? ERROR_TO_LONG : undefined}
                initialValue={row!.comments ? '' + row!.comments : ''}
                onChange={changeHandler}
                disabled={!permission || !securityService.hasPermission(permission) || this.props.period.status === SalaryPeriodStatus.Released || (this.props.period.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod))}
            />
        );
    };

    private _getPermission = (importType: ImportTypeModel) => {
        switch (importType.id) {
            case ImportSalaryType.tickets: return PermissionType.EditTickets;
            case ImportSalaryType.ticketsRefund: return PermissionType.EditTicketRefunds;
            case ImportSalaryType.dutyFree: return PermissionType.EditDutyFree;
            case ImportSalaryType.shiftBonus: return PermissionType.EditShiftBonus;
            default: return null;
        }
    };
}

export { ImportedSalaryTable };
