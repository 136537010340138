import { action, makeObservable, observable, runInAction } from 'mobx';
import { ApiClient, PersonActivitiesInfo } from '@Models';
import { CompletionType, PromiseCompletion } from '@Helpers';

export class Activity {
    label: string;
    value: string;
}

export class ActivityPickerStore {
    private _apiClient = new ApiClient();
    public activities: Activity[] = [];
    public selectedActivities: Activity[] = [];
    public dataLoading: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);

    constructor(selectedValues: string[]) {
        makeObservable(this, {
            selectedActivities: observable,
            activities: observable,
            clearSelectedActivities: action.bound,
            setSelectedActivities: action.bound,
            setupActivities: action.bound,
            loadActivities: action.bound
        });

        const selectedActivities = selectedValues.map(d => ({ label: d, value: d }));
        this.setSelectedActivities(selectedActivities);
    }

    public clearSelectedActivities() {
        this.selectedActivities = [];
    }

    setSelectedActivities(selectedOptions: Activity[]) {
        this.selectedActivities = selectedOptions;
    }

    async loadActivities(periodId: number) {
        const { data: personActivityInfos } = await this._apiClient.personActivitiesGet({ periodId });
        this.setupActivities(personActivityInfos);
    }

    public setupActivities(personActivityInfos: PersonActivitiesInfo[]) {
        let activities: Activity[]  = [];
        const actSet = new Set<string>();

        for (let i = 0; i < personActivityInfos.length; i++) {
            if (!personActivityInfos[i].activities?.length) continue;
            personActivityInfos[i].activities!.forEach(activity => { actSet.add(activity); });
        }

        actSet.forEach(value => {
            activities.push({ label: value, value: value });
        });

        activities.sort((a, b) => {
            if (a.value < b.value) { return -1; }
            if (a.value > b.value) { return 1; }
            return 0;
        });

        runInAction(() => {
            this.activities = activities;
        });
    }
}