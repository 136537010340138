import { makeAutoObservable, runInAction } from 'mobx';
import Axios, { CancelTokenSource } from 'axios';
import { ApiClient, SalaryItemViewModel } from '@Models';
import { CompletionType, PromiseCompletion } from '@Helpers';

export class ImportedSalaryTableStore {
    private _apiClient = new ApiClient();
    private _cancellationSource: CancelTokenSource = Axios.CancelToken.source();
    public dataSource: SalaryItemViewModel[] = [];
    public loader: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);

    constructor() {
        makeAutoObservable(this);
    }

    async loadData(personId: string, periodId: number, importTypeId: number) {
        const params = { personId, periodId, typeId: importTypeId };
        const promise = this._apiClient.salaryByPersonIdPeriodByPeriodIdTypeByTypeIdGet(params, { cancellationToken: this._cancellationSource.token });
        void this.loader.subscribe(promise);
        const { data } = await promise;

        runInAction(() => {
            this.dataSource = data;
        });
    }

    public updateItem = async (modifiedItem: SalaryItemViewModel) => {
        const { id } = modifiedItem;

        await this._apiClient.salarySalaryItemByIdPut(id, modifiedItem);
        const ind = this.dataSource.findIndex(i => i.id === modifiedItem.id);

        runInAction(() => {
            this.dataSource.splice(ind, 1, modifiedItem);
        });
    };

    public deleteItem = async (modifiedItem: SalaryItemViewModel) => {
        const { id } = modifiedItem;

        await this._apiClient.salarySalaryItemByIdDelete({ id });
        const ind = this.dataSource.findIndex(i => i.id === modifiedItem.id);

        runInAction(() => {
            this.dataSource.splice(ind, 1);
        });
    };

    public cancel() {
        this._cancellationSource.cancel('Clear imported salary table');
        this._cancellationSource = Axios.CancelToken.source();
    }
}