import { action, computed, makeObservable, observable } from 'mobx';
import { MatchComparer, stringSearchService } from '@Services';

export class ColumnSort {
    public static readonly ASC: string = 'asc';
    public static readonly DESC: string = 'desc';
}
export class ColumnFilters {
    public static readonly WithValues: string = 'with values';
    public static readonly WithoutValues: string = 'without values';
}

export type FilterState = {
	textFilterValue: string;
	isFunctionaryOnly: boolean;
	dutyFilterValues: string[];
	personFunction: string | null;
	personContracts: string[];
};

export class FilterSectionStore {
    public isFunctionaryOnly: boolean = false;
	public personFunction: string | null = null;
	public personContracts: string[] = [];
	public textFilterValue: string = '';
	public dutyFilterValues: string[] = [];

	private _updateFilterState: (filterState: FilterState) => void;

	constructor(filters: FilterState | null, updateFilterState: (filterState: FilterState) => void) {
		makeObservable(this, {
			searchTerms: computed,
			textComparer: computed,

            isFunctionaryOnly:observable,
			personFunction: observable,
            personContracts: observable,
			textFilterValue: observable,
			dutyFilterValues: observable,
            setFunctonFilter: action.bound,
            setContractFilter: action.bound,
			setTextFilter: action.bound,
			setDutyFilter: action.bound,
            toggleFunctionaryOnly: action.bound
        });
		this._updateFilterState = updateFilterState;

		if (filters) {
			this.isFunctionaryOnly = filters.isFunctionaryOnly;
			this.personFunction = typeof filters.personFunction === 'string' ? filters.personFunction : null;
			this.personContracts = filters.personContracts;
			this.textFilterValue = filters.textFilterValue;
			this.dutyFilterValues = filters.dutyFilterValues;
		}
	}

	setFunctonFilter(pf: string | null) {
		this.personFunction = pf;
		this.saveFilterState();
    }

    setContractFilter(pc: string[]) {
		this.personContracts = !pc[0] ? [] : pc;
		this.saveFilterState();
    }

	setTextFilter(pf?: string) {
        this.textFilterValue = pf || '';
		this.saveFilterState();
	}

	setDutyFilter(pd: string[]) {
		this.dutyFilterValues = pd;
		this.saveFilterState();
	}

    toggleFunctionaryOnly() {
		this.isFunctionaryOnly = !this.isFunctionaryOnly;
		this.saveFilterState();
	}

	saveFilterState() {
		const filters: FilterState = {
			isFunctionaryOnly: this.isFunctionaryOnly,
			personContracts: this.personContracts,
			personFunction: this.personFunction,
			dutyFilterValues: this.dutyFilterValues,
			textFilterValue: this.textFilterValue
		};

		this._updateFilterState(filters);
	}

	get searchTerms(){
		const searchStr = this.textFilterValue;
		return searchStr.split(/[ ,]+/).filter(x => !!x);
	}

	get searchDutyTerms() {
		const searchStr = this.dutyFilterValues;
		return searchStr.map(str => {
			if (str.includes('-')) {
				return str.replaceAll('-', ' / ');
			}

			return str;
		});
	}

	get textComparer(){
		const comparator: MatchComparer = (v, t, s) => {
			try {
				const regex = new RegExp(`${stringSearchService.prepareString(t)}`, 'g');
				regex.lastIndex = s;

				return regex.exec(stringSearchService.prepareString(v) ?? '');
			} catch (e) {
				return null;
			}
		};

		return comparator;
	}
}
