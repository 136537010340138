import { Component } from 'react';
import { observer } from 'mobx-react';

import { TextFilter } from './Components/TextFilter/TextFilter';
import { ContractFilter } from './Components/ContractFilter/ContractFilter';
import { FunctionFilter } from './Components/FunctionFilter/FunctionFilter';

import { contractFilterOptions } from './Components/ContractFilter/contractFilterOptions';

import { FilterSectionStore } from './FilterSectionStore';
import { FunctionaryFilter } from './Components/FunctionaryFilter/FunctionaryFilter';
import { DutyFilter } from './Components/DutyFilter/DutyFilter';
import { PersonActivitiesInfo } from '@Models';
import './filter-section.scss';

type FilterSectionProps = {
    store: FilterSectionStore;
    personActivitiesInfo: PersonActivitiesInfo[];
};

@observer
export class FilterSection extends Component<FilterSectionProps, {}> {
    render() {
        const { store, personActivitiesInfo } = this.props;
        const { personFunction, personContracts, textFilterValue, isFunctionaryOnly, dutyFilterValues } = store;
        const { setContractFilter, setFunctonFilter, setTextFilter, setDutyFilter, toggleFunctionaryOnly } = store;

        return (
            <div className="filter-section">
                <div className="text-filter-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <TextFilter value={textFilterValue} setValue={setTextFilter} />
                    <FunctionaryFilter checked={isFunctionaryOnly} toggle={toggleFunctionaryOnly} />
                </div>
                <DutyFilter
                    setValue={setDutyFilter}
                    selectedValues={dutyFilterValues}
                    personActivitiesInfo={personActivitiesInfo}
                />
                <FunctionFilter selectedFunction={personFunction} setFunctonFilter={setFunctonFilter} />
                <ContractFilter contracts={contractFilterOptions} selectedContracts={personContracts} setContractFilter={setContractFilter} />
            </div>
        );
    }
}