import {DateTime} from '@AppConstants';
import {BaseFormModel, hasMaxLength, isDate, isFloatNumber, isNumberFractionalPartIsLessThanOrEqual, isRequired} from '@Helpers';
import {DateTimeService} from '@Services';
import {makeObservable, observable} from 'mobx';

export class FormModel extends BaseFormModel<FormModel> {
    constructor(defaultDate: Date) {
        super();
        makeObservable(this);
        this.date = DateTimeService.toCustomUiFormat(defaultDate, DateTime.viewDateFormat);
    }

    @observable
    @isRequired()
    @isDate()
    date: string = '';

    @observable
    @isRequired()
    @isFloatNumber()
    @isNumberFractionalPartIsLessThanOrEqual(2)
    amount: string = '';

    @observable
    @hasMaxLength(255)
    comments: string = '';

}
