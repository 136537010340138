import * as React from 'react';
import { RefObject } from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';

import './correction-form-dialog.scss';

import { CorectionFormModel } from './CorectionFormModel';
import { PersonDaySalaryItem, RuleGroupItem } from '@Models';
import { modalService } from '@Components/Modal/ModalService';

import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow, ModalButtonOptions, FormTextarea, FormInput, FormLabel } from '@Components';
import { DateTimeService } from '@Services';
import { runInAction } from 'mobx';


export type UpdateCorrectionDialogProps = {
    RuleGroup: RuleGroupItem;
    correction?: PersonDaySalaryItem;
    value: number;
    calculatedAmount?: number;
};

export type CorrectionResult = {
    amount: number | null;
    comment?: string;
};

@observer
export class UpdateCorrectionDialog extends React.Component<UpdateCorrectionDialogProps> implements IModalDialogContent<CorrectionResult> {
    private _form: CorectionFormModel;
    private _amountRef: RefObject<HTMLInputElement> = React.createRef();
    private _commentRef: RefObject<HTMLTextAreaElement> = React.createRef();
    private _window: ModalWindow<CorrectionResult> | null = null;
    
    constructor(props: UpdateCorrectionDialogProps) {
        super(props);
        this._form = new CorectionFormModel();
        this._form.amount = props.value.toFixed(2);
        this._form.correctionComment = props.correction?.comment ?? '';
    }

    public getModalOptions(window: ModalWindow<CorrectionResult>): ModalDialogOptions<CorrectionResult> {
        this._window = window;
        const buttons: ModalButtonOptions<CorrectionResult>[] = [{
            title: 'Close',
            type: ModalButtonType.Cancel,
            onClick: () => {
                window.close(ModalButtonType.Cancel);
            }
        },
        {
            title: 'Update',
            type: ModalButtonType.Update,
            isDisabled: !this._form.isFormValid,
            onClick: this._onUpdate
        }];

        if (this.props.correction){
            buttons.push({
                title: `Revert to ${(this.props.calculatedAmount ?? 0).toFixed(2)}`,
                type: ModalButtonType.Delete,
                isDisabled: false,
                onClick: this._onDelete,
                color: 'danger',
                alignLeft: true
            });
        }

        return {
            title: 'Correction',
            bodyClassName: 'create-rule-dialog',
            buttons: buttons,
            width: '500px',
            disableAutoFocus: true
        };
    }

    componentDidMount(){
        setTimeout(() => {
            this._amountRef.current?.focus();
        }, 0);
    }

    render() {
        const { correction } = this.props;
        return (<>
                <Row>
                    <Col>
                        <FormLabel formModel={this._form} name="amount"/>
                        <FormInput
                            innerRef={this._amountRef}
                            name="amount"
                            formModel={this._form}
                            type="number"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){
                                    this._commentRef.current?.focus();
                                    e.preventDefault();
                                }
                            }}
                            onBlur={this._handleBlur}
                            onFocus={() => {
                                this._amountRef.current?.select();
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormLabel formModel={this._form} name="correctionComment"/> <small>(Shift + Enter for new line)</small>
                        <FormTextarea
                            innerRef={this._commentRef}
                            name='correctionComment'
                            formModel={this._form}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){
                                    if (!e.shiftKey) {
                                        e.preventDefault();
                                        this._window && this._onUpdate(this._window);
                                        return false;
                                    }
                                    return true;
                                }
                            }}
                        />
                    </Col>
                </Row>
                {correction && <Row className="correction-info">
                    <Col>
                        <small>Modified at {DateTimeService.toUiDateTime(correction.modifiedDateTimeAt)} by {correction.modifiedPrincipalDisplayName ?? correction.modifiedPrincipalEmail}</small>
                    </Col>
                </Row>}
        </>);
    }


    private _handleBlur = () => {
        if (this._form.isValid('amount')){
            runInAction(() => {
                this._form.amount = (+this._form.amount).toFixed(2);
            });
        }
    };

    private _onUpdate = (window: ModalWindow<CorrectionResult>) => {
        if (this._form.validate()){
            window.close(ModalButtonType.Save, {
                amount: +this._form.amount ?? 0,
                comment: this._form.correctionComment
            });
        }
    };

    private _onDelete = async (window: ModalWindow<CorrectionResult>) => {
        if (await modalService.showConfirmation('Do you really want to remove this correction?', { color: 'danger' })){
            window.close(ModalButtonType.Delete);
        }
    };
}
