import React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';

type ContextSubMenuProps = {
    className?: string;
};

@observer
export default class ContextSubMenu extends React.Component<ContextSubMenuProps, {}> {
    private _subMenu: React.RefObject<HTMLDivElement> = React.createRef();
    @observable isPlacedTop: boolean = false;

    constructor(props: ContextSubMenuProps) {
        super(props);
        makeObservable(this);
    }

    render() {
        const className = this._getClassName();
        return (
            <div ref={this._subMenu} className={className}>
                {this.props.children}
            </div>
        );
    }

    @action.bound
    private _getClassName() {
        const className = `contextMenu children-wrap${this.props.className ? ` ${this.props.className}` : ''}`;
        if (!this._subMenu || !this._subMenu.current || this.props.className) {
            return className;
        }

        const subMenuRect = this._subMenu.current.getBoundingClientRect();
        const clickY = subMenuRect.top;
        const screenH = window.innerHeight;
        const rootH = subMenuRect.height;
        const bottom = !((screenH - clickY) > (rootH + 5));
        if (bottom) {
            this.isPlacedTop = true;
        }

        if (this.isPlacedTop) {
            return className + ' children-wrap-top';
        }

        return className;
    }
}
