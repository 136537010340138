import React, { Component } from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FaExclamation, FaClock, FaExclamationCircle } from 'react-icons/fa';

import { ISalaryMonthViewModelResponse, PersonActivitiesInfo, PersonContractInfo, SalaryPeriodResponseItem } from '@Models';
import { stringSearchService } from '@Services';
import { FilterSectionStore } from '../FilterSection/FilterSectionStore';
import PersonContextMenu from '../PersonContextMenu';
import { Column, SizeConfig, Grid, TableRowRenderArgs, CellData } from '@Components';
import { CompletionType, PromiseCompletion } from '@Helpers';
import { PersonError } from './PersonError';

type PersonTableProps = {
    dataSource: ISalaryMonthViewModelResponse[];
    columns?: string[];
    classPreffix?: string;
    scrollTop?: number;
    selectedRow?: ISalaryMonthViewModelResponse;
    highlightedRow?: ISalaryMonthViewModelResponse;
    headerHeight: number;
    scrollHandler: (top: number) => void;
    onRowClick?: (row: ISalaryMonthViewModelResponse) => void;
    onRowDoubleClick?: (row: ISalaryMonthViewModelResponse) => void;
    onRowHover?: (row?: ISalaryMonthViewModelResponse) => void;
    onLoadError: (personId: string) => Promise<string>; 
    sizeConfig?: SizeConfig;
    filterStore: FilterSectionStore;
    loading?: boolean;
    timePeriod: SalaryPeriodResponseItem | null;
    personActivitiesInfoMap: Map<string, PersonActivitiesInfo>;
};

@observer
class PersonTable extends Component<PersonTableProps, {}> {
    @observable.ref private _personListRef: React.RefObject<HTMLDivElement> = React.createRef();
    @observable.shallow private _loaders: Map<string, PromiseCompletion> = new Map();
    private _virtualRowHeight: number = 25;

    constructor(props: PersonTableProps) {
        super(props);
        makeObservable(this);
    }

    render() {
        const { scrollTop, selectedRow, highlightedRow, dataSource, sizeConfig, loading, timePeriod, headerHeight } = this.props;
        const { scrollHandler, onRowClick, onRowDoubleClick, onRowHover } = this.props;

        return (
            <>
                <div ref={this._personListRef}>
                    <Grid<ISalaryMonthViewModelResponse>
                        dataSource={dataSource}
                        virtualized
                        virtualRowHeight={this._virtualRowHeight}
                        onScroll={scrollHandler}
                        scrollTop={scrollTop}
                        highlightedRow={highlightedRow}
                        selectedRow={selectedRow}
                        headerHeight={headerHeight}
                        onRowClick={onRowClick}
                        onRowDoubleClick={onRowDoubleClick}
                        onRowHover={onRowHover}
                        onRowRender={this._rowRender}
                        useScrollbarIndent
                        sizeConfig={sizeConfig}
                        totalRow
                        loading={loading}
                    >
                        <Column<ISalaryMonthViewModelResponse, string> dataField="personCode" caption="Code" maxWidth={50} cellRender={this._codeCellRender}  footerCellRender={this._onFooterRender} />
                        <Column<ISalaryMonthViewModelResponse, string[]> dataField="personFunctions" caption="Function" maxWidth={70} cellRender={this._functionsCellRender} footerCellRender={this._onFooterRender} />
                        <Column<ISalaryMonthViewModelResponse, PersonContractInfo[]> dataField="personContracts" caption="Contract" maxWidth={80} cellRender={this._contractsCellRender} footerCellRender={this._onFooterRender} />
                        <Column<ISalaryMonthViewModelResponse, string> dataField="personFullName" caption="Name" cellRender={this._cellRender} footerCellRender={this._onFooterRender} className="text" />
                    </Grid>
                </div>
                <PersonContextMenu
                    container={this._personListRef}
                    timePeriod={timePeriod}
                    onProgress={this._onPersonProgress}
                />
            </>
        );
    }

    private _onPersonProgress = async (personId: string, operation: Promise<unknown>) => {
        await this.addOperation(personId, operation);
    };

    public async addOperation(personId: string, operation: PromiseLike<unknown>) {
        if (!this._loaders.has(personId)) {
            this._loaders.set(personId, new PromiseCompletion(CompletionType.Pending));
        }

        const loader = this._loaders.get(personId);
        if (loader) {
            await loader.subscribe(operation);
            this._loaders.delete(personId);
        }
    }

    public virtualRowHeight = () => {
        return this._virtualRowHeight;
    };

    private _rowRender = (args: TableRowRenderArgs<ISalaryMonthViewModelResponse>) => {
        args.rowAttributes['data-menu-person-id'] = args.item.personId;

        const loader = this._loaders.get(args.item.personId);
        if (loader?.isPending) {
            args.child = (
                <>
                    {args.child}
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"></div>
                    </div>
                </>
            );
        }
    };

    private _codeCellRender = (cellData: CellData<ISalaryMonthViewModelResponse, string>) => {
        const { onLoadError, personActivitiesInfoMap } = this.props;
        const { row, value } = cellData;
        const { status, personId } = row!;
        const personActivityInfo = personActivitiesInfoMap.get(personId);

        return (
            <>
                {this._renderText(value, true)}
                {(status === 'None') && <span className="period-status" title="No duties loaded yet"><FaClock /></span>}
                {(status === 'Failed') && <PersonError Icon={FaExclamationCircle} title="Failed to import person duties:" onLoadError={() => onLoadError(personId)}/>}
                {(!personActivityInfo?.hasDuties && status === 'Imported') && <PersonError Icon={FaExclamation} title="No duties loaded for imported period"/>}
            </>
        );
    };

    private _cellRender = (cellData: CellData<ISalaryMonthViewModelResponse, string>) => {
        const { value } = cellData;
        return <>{this._renderText(value, true)}</>;
    };
    
    private _functionsCellRender = (cellData: CellData<ISalaryMonthViewModelResponse, string[]>) => {
        const { value } = cellData;
        return (<>
            {value.map((v, index) => <React.Fragment key={index}>
                {index > 0 ? ', ' : ''}
                {this._renderText(v, true)}
            </React.Fragment>)}
        </>);
    };
    
    private _contractsCellRender = (cellData: CellData<ISalaryMonthViewModelResponse, PersonContractInfo[]>) => {
        const { value } = cellData;
        return (<>
            {value.map((v, index) => <React.Fragment key={index}>
                {index > 0 ? ', ' : ''}
                {this._renderText(v.contractType, true)}
            </React.Fragment>)}
        </>);
    };


    private _onFooterRender = () => {
        return null;
    };

    private _renderText(text: string | undefined, shouldSearch: boolean) {
        if (!shouldSearch) {
            return text;
        }

        const { searchTerms, textComparer } = this.props.filterStore;

        const parts = stringSearchService.markMatches(text, searchTerms, textComparer);
        return (
            <>
                {parts.map((p, index) => p.isMatch ? <span className="text-match" style={{ backgroundColor: '#FFFF00' }} key={'t' + index}>{p.text}</span> : p.text)}
            </>
        );
    }
}

export { PersonTable };